import React, {useState} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {useHistory} from 'react-router-dom';
import {AdminAPI, IEmployees} from '../../api/AdminAPI';
import {Button} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {RootState} from '../../store';
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';

type Props = {
    employees: IEmployees[],
    creditOrganizationId: number,
    employeeSuccess: boolean,
    setEmployeeSuccess: React.Dispatch<React.SetStateAction<boolean>>,
}

const StaffTable: React.FC<Props> = (props) => {
    const {employees, creditOrganizationId, setEmployeeSuccess, employeeSuccess} = props;
    const [id, setId] = useState<number>(0);
    const [name, setName] = useState("");
    const [open, setOpen] = useState(false);
    let history = useHistory();
    const sessionId = useSelector((state: RootState) => state.auth.sessionId)

    const employeeClick = (employeeId: number) => {
        return history.push(`/partners/${creditOrganizationId}/${employeeId}`);
    };

    const employeeDelete = (employeeId: number, employeName: string) => {
        setId(employeeId);
        setName(employeName);
        setOpen(true)
    };
    const removeEployee = () => {
        if (sessionId) {
            AdminAPI.deleteEmployee(sessionId, id).then(data => {
                setOpen(false);
                setEmployeeSuccess(!employeeSuccess);
            }).catch(({response}) => alert("Что-то пошло не так"));
        }
    };
    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">№</TableCell>
                            <TableCell>Сотрудник</TableCell>
                            <TableCell align="left">ИНН</TableCell>
                            <TableCell align="center">Действие</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {employees.map((employee, index) => (
                            <TableRow key={employee.employeeId}>
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell component="th" scope="row">
                                    {employee.employeeName}
                                </TableCell>
                                <TableCell align="left">
                                    {employee.employeeInn}
                                </TableCell>
                                <TableCell align="center">
                                    <Button className="tableLinkBlue"
                                            onClick={() => employeeClick(employee.employeeId)}>Открыть</Button>
                                </TableCell>
                                <TableCell align="center">
                                    <Button className="tableLinkRed"
                                            onClick={() => employeeDelete(employee.employeeId, employee.employeeName)}>Удалить</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                className="alertDialog">
                <IconButton className="alertDialog__close" onClick={() => setOpen(false)}>
                    <CloseSharpIcon/>
                </IconButton>
                <DialogContent>
                    <div className="alertDialog__title">Удаление сотрудника</div>
                    <div className="alertDialog__question">Вы уверены, что хотите удалить данного сотрудника?</div>
                    <div className="alertDialog__name">{name}</div>
                    {/*<div className="alertDialog__error">{errMessage}</div>*/}
                    <Box className="alertDialog__group">
                        <Button variant="outlined" color="secondary"
                                onClick={removeEployee}>Удалить</Button>
                        <Button variant="outlined" color="primary"
                                onClick={() => setOpen(false)}>Отмена</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default StaffTable
