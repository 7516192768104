import React from 'react'
import { Grid, Typography, Box, Button, TextField, 
         FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { useStyles } from './AddContractStyle'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useHistory, NavLink } from 'react-router-dom'
import AttachFileIcon from '@material-ui/icons/AttachFile'


const AddContract = () => {
    const classes = useStyles()
    let history = useHistory()

    const [position, setPosition] = React.useState('Физическое лицо')
    const [сurrency, setСurrency] = React.useState('Сом')
    const [pledge, setPledge] = React.useState('Не требуется')
    const [citizen, setСitizen] = React.useState('Гражданин КР')
    const [income, setIncome] = React.useState('за 12 месяцев')

    const inputLabelPosition = React.useRef<HTMLLabelElement>(null)
    const [labelWidthPosition, setLabelWidthPosition] = React.useState(0)

    const inputLabelСurrency = React.useRef<HTMLLabelElement>(null)
    const [labelWidthСurrency, setLabelWidthСurrency] = React.useState(0)

    const inputLabelPledge = React.useRef<HTMLLabelElement>(null)
    const [labelWidthPledge, setLabelWidthPledge] = React.useState(0)

    const inputLabelСitizen = React.useRef<HTMLLabelElement>(null)
    const [labelWidthСitizen, setLabelWidthСitizen] = React.useState(0)

    const inputLabelIncome = React.useRef<HTMLLabelElement>(null)
    const [labelWidthIncome, setLabelWidthIncome] = React.useState(0)

    React.useEffect(() => {
        setLabelWidthPosition(inputLabelPosition.current!.offsetWidth)
        setLabelWidthPledge(inputLabelPledge.current!.offsetWidth)
        setLabelWidthСurrency(inputLabelСurrency.current!.offsetWidth)
        setLabelWidthСitizen(inputLabelСitizen.current!.offsetWidth)
        setLabelWidthIncome(inputLabelIncome.current!.offsetWidth)
    }, [])

    const positionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPosition(event.target.value as string)
    }

    const currencyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setСurrency(event.target.value as string)
    }

    const pledgeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPledge(event.target.value as string)
    }

    const сitizenChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setСitizen(event.target.value as string)
    }
    
    const incomeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setIncome(event.target.value as string)
    }

    return (
        <Grid item xs className="contentGrid">
            <Box mb={2}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Button 
                            variant="contained" 
                            color="inherit" 
                            size="large" 
                            disableElevation
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.goBack()}>
                            Назад
                        </Button>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h4" className={classes.h4}>Новый договор</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                    <form className={classes.root} noValidate autoComplete="off">
                        <Grid container spacing={3} className={classes.mb2}>
                            <Grid item xs={12}>
                                <TextField 
                                    label="Наименование" 
                                    variant="outlined" 
                                    fullWidth 
                                    value="Потребительский кредит “Детский”" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField 
                                    label="Цель кредита" 
                                    variant="outlined" 
                                    fullWidth 
                                    value="На юбилей" />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className={classes.mb3}>
                            <Grid item xs={12} className={classes.mb1}>
                                <Typography className={classes.h5}>Условия по кредиту</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField 
                                    label="Сумма от" 
                                    variant="outlined" 
                                    fullWidth 
                                    value="50 000" 
                                    className="textInputRight"/>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField 
                                    label="Сумма до" 
                                    variant="outlined" 
                                    fullWidth 
                                    value="50 000 000" 
                                    className="textInputRight"/>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel ref={inputLabelСurrency}>
                                        Валюта кредита
                                    </InputLabel>
                                    <Select
                                        value={сurrency}
                                        onChange={currencyChange}
                                        labelWidth={labelWidthСurrency}>
                                        <MenuItem value='Сом'>Сом</MenuItem>
                                        <MenuItem value='Доллар'>Доллар</MenuItem>
                                        <MenuItem value='Евро'>Евро</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className={classes.mb3}>
                            <Grid item xs={2}>
                                <TextField 
                                    label="Срок, месяцев" 
                                    variant="outlined" 
                                    fullWidth 
                                    value="3" 
                                    className="textInputRight"/>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField 
                                    label="Срок до, месяцев" 
                                    variant="outlined" 
                                    fullWidth 
                                    value="48" 
                                    className="textInputRight"/>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField 
                                    label="Ставка в год" 
                                    variant="outlined" 
                                    fullWidth 
                                    value="16,5" 
                                    className="textInputRight"/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className={classes.mb3}>
                            <Grid item xs={12} className={classes.mb1}>
                                <Typography className={classes.h5}>Требования к заемщику</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel ref={inputLabelPosition}>
                                        Тип заемщика
                                    </InputLabel>
                                    <Select
                                        value={position}
                                        onChange={positionChange}
                                        labelWidth={labelWidthPosition}>
                                        <MenuItem value='Физическое лицо'>Физическое лицо</MenuItem>
                                        <MenuItem value='Юридическое лицо'>Юридическое лицо</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} className={classes.mb2}>
                            <Grid item xs={2}>
                                <TextField 
                                    label="Возраст от" 
                                    variant="outlined" 
                                    fullWidth 
                                    value="21" 
                                    className="textInputRight"/>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField 
                                    label="Возраст до" 
                                    variant="outlined" 
                                    fullWidth 
                                    value="65" 
                                    className="textInputRight"/>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel ref={inputLabelСitizen}>
                                        Резиденство
                                    </InputLabel>
                                    <Select
                                        value={citizen}
                                        onChange={сitizenChange}
                                        labelWidth={labelWidthСitizen}>
                                        <MenuItem value='Гражданин КР'>Гражданин КР</MenuItem>
                                        <MenuItem value='Гражданин РФ'>Гражданин РФ</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel ref={inputLabelIncome}>
                                        Документ о доходах
                                    </InputLabel>
                                    <Select
                                        value={income}
                                        onChange={incomeChange}
                                        labelWidth={labelWidthIncome}>
                                        <MenuItem value='за 12 месяцев'>за 12 месяцев</MenuItem>
                                        <MenuItem value='за 6 месяцев'>за 6 месяцев</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel ref={inputLabelPledge}>
                                        Наличие залога
                                    </InputLabel>
                                    <Select
                                        value={pledge}
                                        onChange={pledgeChange}
                                        labelWidth={labelWidthPledge}>
                                        <MenuItem value='Не требуется'>Не требуется</MenuItem>
                                        <MenuItem value='Требуется'>Требуется</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.mb6}>
                            <Box className={classes.download}><AttachFileIcon /> Загрузить текстовую версию договора</Box>
                        </Grid>
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={6}>
                                <Button variant="outlined" size="large" disableElevation className={classes.cancel}>
                                    Отмена
                                </Button>
                                <NavLink to="/loanAgreement">
                                    <Button variant="contained" color="primary" size="large" disableElevation>
                                        Добавить
                                    </Button>
                                </NavLink>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AddContract
