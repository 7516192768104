import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    h4: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
        fontSize: 32
    },
    h5: {
        fontWeight: 600,
        fontSize: 16
    }, 
    center: {
        textAlign: 'center'
    },
    right: {
        textAlign: 'right'
    }
}))

export { useStyles }