import React from 'react'
import { Grid, Typography, Box, Button, Tabs, Tab } from '@material-ui/core'
import { useStyles } from './ClientInfoStyle'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { withRouter, useHistory, NavLink } from 'react-router-dom'
import ClientDetail from '../../ClientDetail/ClientDetail'
import ClientDocumentTable from '../../bricks/ClientDocumentTable'
import SalaryTable from '../../bricks/SalaryTable'


const ClientInfo = () => {
    const classes = useStyles()
    let history = useHistory()
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue)
    };

    return (
        <Grid item xs className="contentGrid">
            <Box mb={2}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                        <Button 
                            variant="contained" 
                            color="inherit" 
                            size="large" 
                            disableElevation
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.goBack()}>
                            Назад
                        </Button>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="h4" className={classes.h4}>Абдышева Асия Нурлановна</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                    <Tabs value={value} onChange={handleChange} className={classes.tabs}>
                        <Tab label="Информация" {...a11yProps(0)} />
                        <Tab label="Ишеним" {...a11yProps(1)} />
                        <Tab label="Заработная плата" {...a11yProps(2)} />
                        <Tab label="Патент" {...a11yProps(3)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <ClientDetail />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        1
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <SalaryTable />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        3
                    </TabPanel>
                    <Box className={classes.documentTableBox}>
                        <Box mb={3}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={8}>
                                    <Typography variant="h4" className={classes.h4}>Документы</Typography>
                                </Grid>
                                <Grid item xs={4} className={classes.right}>
                                    <Typography align="right">
                                        <NavLink to="/newContract">
                                            <Button variant="contained" color="primary" size="large" disableElevation>
                                                Новый договор
                                            </Button>
                                        </NavLink>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <ClientDocumentTable />
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withRouter(ClientInfo)

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
  
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            >
            {value === index && <Box p={3}>{children}</Box>}
            </Typography>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}