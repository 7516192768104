import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    h4: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
        fontSize: 32,
        paddingBottom: 5
    },
    h5: {
        fontWeight: 600,
        fontSize: 20
    }, 
    center: {
        textAlign: 'center'
    },
    right: {
        textAlign: 'right'
    },
    tableTitle: {
        marginBottom: 30,
        fontSize: 24,
        fontWeight: 600,
        color: '#000000'
    },
    confirmation: {
        '& .MuiInputBase-root': {
            color: '#000000',
            borderRadius: 8
        },
        '& .MuiButtonBase-root': {
            padding: '15px 30px'
        },
        '& .MuiTypography-root': {
            marginBottom: 15
        },
        '& .MuiFormControl-root': {
            maxWidth: 188,
            paddingRight: 20
        }
    },
    confirmationBox: {
        display: 'flex'
    },
    confirmationDesc: {
        color: '#757575'
    },
    close: {
        fontSize: 40,
        color: '#FF0000'
    },
    tabs: {
        minHeight: 76,
        overflow: 'inherit',
        '& .MuiTabs-scroller': {
            overflow: 'inherit !important'
        },
        '& .MuiTabs-flexContainer': {
            height: 76,
            display: 'flex',
            alignItems: 'center'
        },
        '& .MuiButtonBase-root': {
            opacity: 1,
            minWidth: 220,
            minHeight: 76,
            padding: '15px',
            boxShadow: '0px 2px 30px rgba(0, 0, 0, 0.15)',
            borderRadius: 8,
            background: '#FFFFFF',
            textTransform: 'none',
            fontSize: 20,
            fontWeight: 600,
            color: '#333333',
            margin: 8,
            '&:first-child': {
                marginLeft: 0
            },
            '&:last-child': {
                marginRight: 0
            }
        },
        '& .Mui-selected': {
            background: '#2AA3EF',
            color: '#ffffff'
        },
        '& .MuiTabs-indicator': {
            display: 'none'
        }
    },
    documentTableBox: {
        maxWidth: 877,
        paddingLeft: 24
    },
    contractBox: {
        boxShadow: '0px 2px 30px rgba(0, 0, 0, 0.15)',
        borderRadius: 8,
        opacity: 0.8,
        padding: 24,
        fontSize: 14,
        display: 'block',
        color: '#333333',
        transition: 'all .3s ease',
        position: 'relative',
        '& svg.MuiSvgIcon-root': {
            position: 'absolute',
            top: 16,
            right: 12,
            color: '#fff'
        },
        '&:hover': {
            background: '#2AA3EF',
            color: '#fff',
            boxShadow: 'none'
        },
        '&.active': {
            background: '#2AA3EF',
            color: '#fff',
            boxShadow: 'none'
        }
    },
    mb2: {
        marginBottom: 10
    },
    title: {
        textTransform: 'uppercase',
        display: 'block',
        marginBottom: 3
    },
    centerButton: {
        margin: 'auto',
        textAlign: "center",
        display: 'block',
        marginTop: 20,
    }
}))

export { useStyles }