import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
import { Grid, Typography, Box, Button } from '@material-ui/core'
import { useStyles } from './PersonStyle'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import PersonDetail from '../../PersonDetail/PersonDetail'
// import DocumentTable from '../../bricks/DocumentTable'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { CustomersAPI, PassportInfo, IUserReports, IGetCertificate } from '../../../api/CustomersAPI';
import DocumentTable from '../../bricks/DocumentTable';

type Props = ReturnType<typeof mapStateToProps>;

const Person:React.FC<Props> = ({ sessionId }) => {
    const classes = useStyles();
    let history = useHistory();
    const match = useRouteMatch();
    //@ts-ignore
    const customerId = parseInt(match.params.customerId);
    const [passportInfo, setPassportInfo] = useState<PassportInfo | null>(null);
    const [documents, setDocuments] = useState<IUserReports | null>(null);
    const [certificate, setCertificate] = useState<IGetCertificate | null>(null);

    useEffect(() => {
        if(sessionId) {
            CustomersAPI.getPassportInfo(sessionId, customerId).then(data => {
                setPassportInfo(data);
            }).catch(({response}) => alert(response));

            CustomersAPI.getUserReports(sessionId, customerId).then(data => {
                setDocuments(data);
            }).catch(({response}) => alert(response));

            CustomersAPI.getCertificate(sessionId, customerId).then(data => {
                setCertificate(data);
            }).catch(({response}) => alert(response));
        }
    }, [sessionId, customerId]);

    return (
        <Grid item xs className="contentGrid">
            <Box mb={3}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                        <Button
                            variant="contained"
                            color="inherit"
                            size="large"
                            disableElevation
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.goBack()}>
                            Назад
                        </Button>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="h4" className={classes.h4}>{passportInfo && passportInfo.userName}</Typography>
                    </Grid>
                </Grid>
            </Box>
            {passportInfo &&
                <PersonDetail passportInfo={passportInfo} certificate={certificate} />
            }
            <Grid container spacing={2} justify="flex-end">
                <Grid item xs={10}>
                    {documents && passportInfo &&
                        <>
                            <Typography variant="h5" className={classes.tableTitle}>Документы</Typography>
                            <DocumentTable documents={documents} userInn={passportInfo.userInn} />
                        </>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state: RootState) => ({
    sessionId: state.auth.sessionId,
});

export default connect(mapStateToProps)(Person);
