import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../store';
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import {IUserReports} from '../../api/CustomersAPI'
import Moment from 'react-moment';
import 'moment/locale/ru';
import {PdfAPI} from '../../api/PdfAPI';
import {Button} from '@material-ui/core';
import {PersonAPI} from "../../api/PersonAPI";

type Props = ReturnType<typeof mapStateToProps> & {
    documents: IUserReports,
    userInn: string;
};

const DocumentTable: React.FC<Props> = ({documents, sessionId, userInn}) => {
    const [doc, setDoc] = useState([]);
    useEffect(() => {
        PersonAPI.getUserDocuments().then(data => {
            setDoc(data.documents)
        })
    }, [])
    const downloadIshenimReport = () => {
        if (sessionId) {
            PdfAPI.getIshenimReportPdf(sessionId, documents.customerId).then(data => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Кредитная история.pdf');
                document.body.appendChild(link);
                link.click();
            });
        }
    }

    const downloadServiceReport = () => {
        if (sessionId) {
            PdfAPI.getSettlementsServiceReportPdf(sessionId, documents.customerId).then(data => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Доходы.pdf');
                document.body.appendChild(link);
                link.click();
            });
        }
    };
    const downloadUserDocument = (type: any, linkText: string) => {
        if (sessionId) {
            PersonAPI.downloadUserDocument(sessionId, documents.customerId, type).then(data => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${linkText} данные.pdf`);
                document.body.appendChild(link);
                link.click();
            })
        }
    };
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">№</TableCell>
                        <TableCell>Клиент</TableCell>
                        <TableCell align="left">ИНН</TableCell>
                        <TableCell align="left">Запрос</TableCell>
                        <TableCell align="left">Дата запроса</TableCell>
                        <TableCell align="right">Действие</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell align="left">1</TableCell>
                        <TableCell component="th" scope="row">{documents.customerName}</TableCell>
                        <TableCell align="left">{userInn}</TableCell>
                        <TableCell align="left">Кредитная история</TableCell>
                        <TableCell align="left">
                            {documents.ishenimReport ?
                                <Moment format="D MMMM YYYY">{documents.ishenimReport.requestDate}</Moment> : ''}
                        </TableCell>
                        <TableCell align="right">
                            {documents.ishenimReport ? <Button className="tableLinkBlue"
                                                               onClick={downloadIshenimReport}>Документ</Button> : 'Не запрашивали'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left">2</TableCell>
                        <TableCell component="th" scope="row">{documents.customerName}</TableCell>
                        <TableCell align="left">{userInn}</TableCell>
                        <TableCell align="left">Доходы</TableCell>
                        <TableCell align="left">
                            {documents.settlementsServiceReport ? <Moment
                                format="D MMMM YYYY">{documents.settlementsServiceReport.requestDate}</Moment> : ''}
                        </TableCell>
                        <TableCell align="right">
                            {documents.settlementsServiceReport ? <Button className="tableLinkBlue"
                                                                          onClick={downloadServiceReport}>Документ</Button> : 'Не запрашивали'}
                        </TableCell>
                    </TableRow>
                    {doc.map((elem: any, idx: number) => (
                        <TableRow key={elem.documentType}>
                            <TableCell align="left">{3 + idx}</TableCell>
                            <TableCell component="th" scope="row">{documents.customerName}</TableCell>
                            <TableCell align="left">{userInn}</TableCell>
                            <TableCell align="left">{elem.text + ' ' + elem.linkText}</TableCell>
                            <TableCell align="left">
                                {documents.settlementsServiceReport ? <Moment
                                    format="D MMMM YYYY">{documents.settlementsServiceReport.requestDate}</Moment> : ''}
                            </TableCell>
                            <TableCell align="right">
                                <Button className="tableLinkBlue"
                                        onClick={() => downloadUserDocument(elem.documentType, elem.text + ' ' + elem.linkText)}>Документ</Button>
                            </TableCell>
                        </TableRow>
                    ))}

                    {/*<TableRow>*/}
                    {/*    <TableCell align="left">4</TableCell>*/}
                    {/*    <TableCell component="th" scope="row">{documents.customerName}</TableCell>*/}
                    {/*    <TableCell align="left">{userInn}</TableCell>*/}
                    {/*    <TableCell align="left">Согласие на запрос в Ишеним</TableCell>*/}
                    {/*    <TableCell align="left">*/}
                    {/*        {documents.settlementsServiceReport ? <Moment*/}
                    {/*            format="D MMMM YYYY">{documents.settlementsServiceReport.requestDate}</Moment> : ''}*/}
                    {/*    </TableCell>*/}
                    {/*    <TableCell align="right">*/}
                    {/*        {documents.settlementsServiceReport ? <Button className="tableLinkBlue"*/}
                    {/*                                                      onClick={downloadServiceReport}>Документ</Button> : 'Не запрашивали'}*/}
                    {/*    </TableCell>*/}
                    {/*</TableRow>*/}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const mapStateToProps = (state: RootState) => ({
    sessionId: state.auth.sessionId,
});

export default connect(mapStateToProps)(DocumentTable)
