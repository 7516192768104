import React from 'react'
import { Grid, Typography, Box, Button } from '@material-ui/core'
import Paginations from '../../Paginatins/Paginations'
import { useStyles } from './StatisticDetailsStyle'
import MonthSelect from '../../bricks/MonthSelect'
import YearSelect from '../../bricks/YearSelect'
import Search from '../../bricks/Search'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useHistory } from 'react-router-dom'
import StatisticDetailsTable from '../../bricks/StatisticDetailsTable'


const StatisticDetails = () => {
    const classes = useStyles()
    let history = useHistory()

    return (
        <Grid item xs className="contentGrid">
            <Box mb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Button 
                            variant="contained" 
                            color="inherit" 
                            size="large" 
                            disableElevation
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.goBack()}>
                            Назад
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h4" className={classes.h4}>Азаматов Азамат</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Search />
                    </Grid>
                    <Grid item xs={3} className={classes.right}>
                        <MonthSelect />
                        <YearSelect />
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                    <StatisticDetailsTable />
                    <Grid item xs={12}>
                        <Box mb={3} mt={3}>
                            <Typography variant="h5" align="right" className={classes.h5}>Итого запросов: 3</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className={classes.center}>
                        <Paginations count={10} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default StatisticDetails