import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { AppBar, Toolbar, Button, Typography, Grid } from '@material-ui/core'
import { useStyles } from './HeaderStyle'
import { withRouter, useHistory } from 'react-router-dom'
import { headerTitle } from '../../utils/headerTitle';
import { AuthSessionAPI } from '../../api/AuthSessionAPI';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const Header:React.FC<Props> = ({ sessionId, logoutAction, getUserInfo }) => {
    const classes = useStyles()
    const { pathname } = window.location;
    let history = useHistory();

	if (pathname.match(/partnerRegistration/)){
		return null
    }

    const handleLogout = () => {
        if(sessionId) {
            AuthSessionAPI.logout(sessionId);
            logoutAction();
            return history.push('/');
        }
    }

    return (
        <AppBar position="static" color="transparent" className={classes.colorTransparent}>
            <Grid container>
                <Grid item xs={12}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            {headerTitle()}
                        </Typography>
                        {getUserInfo && 
                            <Typography className={classes.group}>
                                <span className={classes.name}>{getUserInfo.employeeName}</span> 
                                <span>“{getUserInfo.organizationName}”</span>
                            </Typography>
                        }
                        <Button color="default" className={classes.login} onClick={handleLogout}>Выйти</Button>
                    </Toolbar>
                </Grid>   
            </Grid>
        </AppBar>
    )
}

const mapStateToProps = (state: RootState) => ({
    sessionId: state.auth.sessionId,
    getUserInfo: state.auth.getUserInfo,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    logoutAction: () => dispatch(actions.auth.logoutAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));