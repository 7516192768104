import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
import { Grid, Typography, Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useHistory, useRouteMatch } from 'react-router-dom'
import CreateIcon from '@material-ui/icons/Create'
import { AdminAPI, IGetOrganizationInfo } from '../../../api/AdminAPI';

type Props = ReturnType<typeof mapStateToProps>;

const Requisites:React.FC<Props> = ({ sessionId }) => {
    const classes = useStyles();
    let history = useHistory();
    const match = useRouteMatch();
    //@ts-ignore
    const organizationId = parseInt(match.params.organizationId);
    const [organizationInfo, setOrganizationInfo] = useState<IGetOrganizationInfo | null>(null);

    useEffect(() => {
        if(sessionId) {
            AdminAPI.getOrganizationInfo(sessionId, organizationId).then(data => {
                setOrganizationInfo(data);
            })
        }
    }, [sessionId, organizationId]);

    const editRequisites = (creditOrganizationId: number) => {
        return history.push(`/editRequisites/${creditOrganizationId}`);
    }

    return (
        <Grid item xs className="contentGrid">
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Button 
                        variant="contained" 
                        color="inherit" 
                        size="large" 
                        disableElevation
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => history.push('/partners')}>
                        Назад
                    </Button>
                </Grid>
                <Grid item xs={10}>
                    <Grid container spacing={2} justify="space-between" className={classes.maxWidth}>
                        <Grid item>
                            <Typography variant="h4" className={classes.h4}>Реквизиты</Typography>
                        </Grid>
                        <Grid item>
                            {organizationInfo && 
                                <Button  className={classes.edit} onClick={() => editRequisites(organizationInfo.creditOrganizationId)} startIcon={<CreateIcon />}> Редактировать</Button>
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            {organizationInfo && 
                                <Box className={classes.boxItem}>
                                    <Box className={classes.list}>
                                        <Box><strong>Организация</strong></Box>
                                        <span>{organizationInfo.organizationName}</span>
                                    </Box>
                                    <Box className={classes.list}>
                                        <Box><strong>ИНН</strong></Box>
                                        <span>{organizationInfo.organizationInn}</span>
                                    </Box>
                                    <Box className={classes.list}>
                                        <Box><strong>Адрес</strong></Box>
                                        <span>{organizationInfo.organizationAddress}</span>
                                    </Box>
                                    <Box className={classes.list}>
                                        <Box><strong>Код ОКПО</strong></Box>
                                        <span>{organizationInfo.okpo}</span>
                                    </Box>
                                    <Box className={classes.list}>
                                        <Box><strong>Районая ГНИ</strong></Box>
                                        <span>{organizationInfo.nameOfTaxAuthority}</span>
                                    </Box>
                                    <Box className={classes.list}>
                                        <Box><strong>Банк</strong></Box>
                                        <span>{organizationInfo.bank}</span>
                                    </Box>
                                    <Box className={classes.list}>
                                        <Box><strong>БИК</strong></Box>
                                        <span>{organizationInfo.bik}</span>
                                    </Box>
                                    <Box className={classes.list}>
                                        <Box><strong>Расчетный счет</strong></Box>
                                        <span>{organizationInfo.paymentAccount}</span>
                                    </Box>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state: RootState) => ({
    sessionId: state.auth.sessionId,
});

export default connect(mapStateToProps)(Requisites);

const useStyles = makeStyles(theme => ({
    h4: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
        fontSize: 32,
        marginBottom: 8,
    },
    h5: {
        fontWeight: 600,
        fontSize: 20
    }, 
    center: {
        textAlign: 'center'
    },
    right: {
        textAlign: 'right'
    },
    maxWidth: {
        maxWidth: 716
    },
    boxItem: {
        background: '#FFFFFF',
        boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.15)',
        borderRadius: 12,
        padding: 24,
        minHeight: 213,
        maxWidth: 650
    },
    headItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8
    },
    tableTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        fontSize: 20,
        fontWeight: 600,
        marginRight: 20
    },
    link: {
        textDecoration: 'underline',
        paddingTop: 2,
        '&:hover': {
            textDecoration: 'none'
        }
    },
    list: {
        marginBottom: 24,
        '&:last-child': {
            marginBottom: 0
        },
        '& strong': {
            paddingBottom: 8,
            display: 'block',
            fontWeight: 600
        }
    },
    mb5: {
        marginBottom: 25
    },
    mt6: {
        marginTop: 34
    },
    edit: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'underline',
        color: '#007AFF',
        '&:hover': {
            textDecoration: 'none'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1.2rem',
            marginRight: 4
        }
    }
}))