import axios from 'axios';
import { creditBureauUrl } from '../utils/baseUrl';

const instance = axios.create({
    baseURL: `${creditBureauUrl()}api/Excel/`,
    responseType: 'blob',
    headers: {
        "Access-Control-Allow-Origin": "*",
        "apiKey": "DE57A23D-D449-4279-A2FB-14A3C5F650F2",
    }
})

export const ExcelApi = {
    async getOrganizationsStatistics(sessionId: string, dateFrom: Date, dateTo: Date) {
        return await instance.post(`GetOrganizationsStatistics/${sessionId}`, {dateFrom, dateTo}).then(res => {
            return res.data;
        });
    },
    async getOrganizationsCustomers(sessionId: string, creditOrganizationId: number, dateFrom: Date, dateTo: Date) {
        return await instance.post(`GetOrganizationsCustomers/${sessionId}`, {creditOrganizationId, dateFrom, dateTo}).then(res => {
            return res.data;
        });
    },
}