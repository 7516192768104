export interface AdminState {
    startDate: Date,
    endDate: Date,
    employeeId: number  | null,
    search: string | null,
}

export enum AdminActionsTypes {
    START_DATE = 'START_DATE',
    END_DATE = 'END_DATE',
    EMPLOYEE_ID = 'EMPLOYEE_ID',
    SEARCH = 'SEARCH',
}

export interface IGetEmployee {
    creditOrganizationId: number,
    employeeId: number,
    employeeName: string,
    employeeInn: string,
    pin: string,
    phone: string,
    canViewStatistics: boolean,
    canCreateEmployees: boolean,
}
  