import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { OutlinedInput, FormControl, InputLabel } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const Search:React.FC<Props> = ({ searchAction, search }) => {
    const classes = useStyles();

    const searchChange = (value: string) => {
        if(value === '') {
            searchAction(null) 
        } else {
            searchAction(value) 
        }
    }

    return (
        <FormControl variant="outlined" className={classes.root}>
            <InputLabel htmlFor="outlined-adornment-amount">Поиск</InputLabel>
            <OutlinedInput
                value={search || ''}
                onChange={(event) => searchChange(event.target.value)}
                endAdornment={<SearchIcon />}
                labelWidth={48}
                margin="dense"
            />
        </FormControl>
    )
}

const mapStateToProps = (state: RootState) => ({
    search: state.admin.search,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    employeeIdAction: (employeeId: number) => dispatch(actions.admin.employeeIdAction(employeeId)),
    searchAction: (search: string | null) => dispatch(actions.admin.searchAction(search)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        maxWidth: 310,
        width: '100%',
        textAlign: 'left',
        marginRight: 12,
        '& .MuiInputBase-root': {
            borderRadius: 4
        },
        '& .MuiInputBase-input': {
          paddingLeft: 16,
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(16px, 13px) scale(1)'
        },
        '& .MuiInputLabel-shrink': {
            transform: 'translate(16px, -6px) scale(0.75)'
        },
        '& .MuiSvgIcon-root': {
            color: '#9d9d9d'
        }
    }
  })
)