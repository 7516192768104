import React from 'react'
import { Grid, Typography, Box } from '@material-ui/core'
import Paginations from '../../Paginatins/Paginations'
import { useStyles } from './StatisticStyle'
import StatisticTable from '../../bricks/StatisticTable'
import MonthSelect from '../../bricks/MonthSelect'
import YearSelect from '../../bricks/YearSelect'
import Search from '../../bricks/Search'
import MenuDrawer from '../../MenuDrawer/MenuDrawer'


const Statistic = () => {
    const classes = useStyles()

    return (
        <>
            <MenuDrawer />
            <Grid item xs className="contentGrid">
                <Box mb={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Typography variant="h4" className={classes.h4}>Статистика</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Search />
                        </Grid>
                        <Grid item xs={5} className={classes.right}>
                            <MonthSelect />
                            <YearSelect />
                        </Grid>
                    </Grid>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <StatisticTable />
                    </Grid>
                    <Grid item xs={12}>
                        <Box mb={3}>
                            <Typography variant="h5" align="right" className={classes.h5}>Итого запросов: 600</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className={classes.center}>
                        <Paginations count={10} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Statistic