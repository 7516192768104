import React, {Dispatch, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {RootState, actions} from '../../store';
import {ThunkDispatch} from 'redux-thunk';
import {Action, AnyAction} from 'redux';
import {TextField, Button, CircularProgress, InputAdornment} from '@material-ui/core';
import {AuthtorizationIcon, LogoIcon} from '../../Icons/Icons';
import {AuthAPI} from '../../api/AuthAPI';
import SnackbarAlert from '../bricks/SnackbarAlert';
import ReactCodeInput from 'react-code-input';
import './Authorization.scss';
import {Redirect} from 'react-router-dom';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>

const Authorization: React.FC<Props> = (props) => {
    const {employeePinConfirmAction, getUserInfo} = props;
    const [innOrganisation, setOrganisation] = useState('');
    const [innEmployee, setInnEmployee] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [notification, setNotification] = useState(false);
    const [message, setMessage] = useState('');
    const [helperText, setHelperText] = useState('');
    const [further, setFurther] = useState(false);
    const [next, setNext] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loader, setLoader] = useState(false);

    const catchError = (message: string) => {
        setMessage(message);
        setNotification(true);
        setFurther(false);
        setLoader(false);
    }

    const errorInn = (data: any) => {
        if (data.code === 429) {
            setFurther(true);
        } else {
            setFurther(false);
        }
        setMessage(data.message);
        setNotification(true);
        setLoader(false);
    }

    useEffect(() => {
        if (innOrganisation.length === 14) {
            AuthAPI.getNameByInn(innOrganisation).then(res => {
                if (res.statusText === 'OK') {
                    setFurther(true);
                }
                setNotification(false);
                setHelperText(res.data.name);
            }).catch(({response}) => catchError(response.data.message));
        } else {
            setHelperText('');
            setFurther(false);
        }
    }, [innOrganisation]);

    useEffect(() => {
        if (innEmployee.length === 14) {
            AuthAPI.getNameByInn(innEmployee).then(res => {
                if (res.statusText === 'OK') {
                    setFurther(true);
                }
                setNotification(false);
                setHelperText(res.data.name);
            }).catch(({response}) => catchError(response.data.message));
        } else {
            setHelperText('');
            setFurther(false);
        }
    }, [innEmployee]);

    const innOrganisationClick = () => {
        setLoader(true);
        if (further) {
            setTimeout(() => {
                setNext(true);
                setHelperText('');
                setLoader(false);
            }, 700)
        }
    }

    const innEmployeeClick = () => {
        setLoader(true);
        if (further) {
            AuthAPI.employeeCheck(innOrganisation, innEmployee).then(data => {
                setTimeout(() => {
                    setSuccess(data.success);
                    setLoader(false);
                }, 700)
            }).catch(({response}) => errorInn(response.data));
            setHelperText('');
        }
    }

    const pinCodeClick = () => {
        setLoader(true);
        AuthAPI.employeePinConfirm(pinCode, innOrganisation, innEmployee).then(data => {
            if (data.approve === true) {
                setNotification(false);
                employeePinConfirmAction(data.sessionId, true);
                setLoader(false);
                if (getUserInfo && !getUserInfo.isIshenim && getUserInfo.canViewStatistics) {
                    return <Redirect to="/statistics"/>
                }
                if (getUserInfo && !getUserInfo.isIshenim && getUserInfo.canCreateEmployees) {
                    return <Redirect to="/partners"/>
                }
            } else {
                setMessage('Вы ввели неправильный пинкод');
                setNotification(true);
            }
        }).catch(({response}) => catchError(response.data.message));
    }

    const onKeyDownInnOrganisation = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            innOrganisationClick();
        }
    }

    const onKeyDownInnEmployee = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            innEmployeeClick();
        }
    }

    const innView = () => {
        return (
            <div className="authorization__form">
                <div>
                    <TextField
                        inputProps={{
                            maxLength: 14,
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        autoFocus
                        fullWidth
                        label="Введите ИНН организации"
                        value={innOrganisation}
                        onChange={(event) => setOrganisation(event.target.value)}
                        error={notification}
                        helperText={helperText}
                        onKeyDown={onKeyDownInnOrganisation}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <span className="count">{innOrganisation.length} / 14</span>
                                </InputAdornment>
                            ),
                        }}/>
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={innOrganisationClick}
                    disableElevation
                    disabled={further && innOrganisation.length === 14 ? false : true}>
                    {loader ? <CircularProgress size={24} color="inherit"/> : 'Далее'}
                </Button>
            </div>
        )
    }

    const innEmployeeView = () => {
        return (
            <div className="authorization__form">
                <div className="textField">
                    <TextField
                        inputProps={{
                            maxLength: 14,
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        label="Введите ИНН Сотрудника"
                        value={innEmployee}
                        onChange={(event) => setInnEmployee(event.target.value)}
                        error={notification}
                        helperText={helperText}
                        onKeyDown={onKeyDownInnEmployee}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <span className="count">{innEmployee.length} / 14</span>
                                </InputAdornment>
                            ),
                        }}/>
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={innEmployeeClick}
                    disableElevation
                    disabled={further && innEmployee.length === 14 ? false : true}>
                    {loader ? <CircularProgress size={24} color="inherit"/> : 'Войти'}
                </Button>
            </div>
        )
    }

    const pinCodeView = () => {
        return (
            <div className="authorization__form">
                <div className="textField">
                    <div className="textField__label">Введите пин-код</div>
                    <ReactCodeInput
                        type='password'
                        fields={4}
                        value={pinCode}
                        onChange={(event: any) => setPinCode(event)}
                        name="pin"
                        inputMode="numeric"/>
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={pinCodeClick}
                    disableElevation
                    disabled={pinCode.length === 4 ? false : true}>
                    {loader ? <CircularProgress size={24} color="inherit"/> : 'Войти'}
                </Button>
            </div>
        )
    }

    return (
        <div className="authorization">
            <div className="authorization__wrap">
                <div className="ihenim">
                    <LogoIcon/>
                    <div className="ihenim__content">
                        <div className="ihenim__title">Ишеним</div>
                        <div className="ihenim__label">Кредитное бюро</div>
                    </div>
                </div>
                <div className="authorization__content">
                    <div className="authorization__title">Вход в систему</div>
                    {success ? pinCodeView() : (!next ? innView() : innEmployeeView())}
                </div>
            </div>
            <AuthtorizationIcon/>
            <SnackbarAlert
                notification={notification}
                setNotification={setNotification}
                message={message}
                severity="error"
                vertical="top"
                horizontal="center"/>
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    isAuthorized: state.auth.isAuthorized,
    getUserInfo: state.auth.getUserInfo,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    employeePinConfirmAction: (sessionId: string, isAuthorized: boolean) => dispatch(actions.auth.employeePinConfirmAction(sessionId, isAuthorized)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Authorization);
