import React from 'react'
import Pagination from '@material-ui/lab/Pagination'
import { useStyles } from './PaginationsStyle'

type Props = {
    count: number
}

const Paginations:React.FC<Props> = ({ count }) => {
    const classes = useStyles()
    return (
        <Pagination count={count} shape="rounded" className={classes.root} />
    )
}

export default Paginations