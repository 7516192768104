import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
import { Grid, Typography, Box, Button, TextField, Divider, FormControlLabel, Checkbox,
         FormControl, InputLabel, OutlinedInput, InputAdornment } from '@material-ui/core'
import { useStyles } from './PartnerRegistrationStyle'
import CreateIcon from '@material-ui/icons/Create';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { IGetOrganizationInfo, AdminAPI } from '../../../api/AdminAPI';

type Props = ReturnType<typeof mapStateToProps>;

const PartnerRegistration:React.FC<Props> = ({ sessionId }) => {
    const classes = useStyles();
    let history = useHistory();
    const match = useRouteMatch();
    //@ts-ignore
    const organizationId = parseInt(match.params.organizationId);
    const [organizationInfo, setOrganizationInfo] = useState<IGetOrganizationInfo>({
        okpo: "",
        codeOfTaxAuthority: "",
        nameOfTaxAuthority: "",
        organizationAddress: "",
        bank: "",
        bik: "",
        paymentAccount: "",
        accessForDocumentCirculation: true,
        accessForCreditHistory: true,
        creditOrganizationId: organizationId ? organizationId : 0,
        organizationName: "",
        organizationInn: "",
    });

    useEffect(() => {
        if(sessionId && organizationId) {
            AdminAPI.getOrganizationInfo(sessionId, organizationId).then(data => {
                setOrganizationInfo(data);
            })
        }
    }, [sessionId, organizationId]);

    const saveRegistration= () => {
        if(sessionId && organizationInfo) {
            AdminAPI.setOrganizationInfo(sessionId, organizationInfo).then(data => {
                if(data.success) {
                    if(organizationInfo.creditOrganizationId === 0) {
                        return history.push('/partners');
                    } else {
                        // return history.push(`/requisites/${organizationInfo.creditOrganizationId}`);
                        return history.push('/partners')
                    }
                }
            }).catch(({response}) => alert(response.data.message));
        }
    }

    return (
        <Grid item xs className="contentGrid">
            <Box mb={3}>
                <Grid container spacing={2} alignItems="center" justify="center">
                    <Grid item xs={8}>
                        <Typography variant="h4" className={classes.h4}>Создание партнера</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={2} justify="center">
                <Grid item xs={8}>
                    {organizationInfo &&
                        <form className={classes.root} noValidate autoComplete="off">
                            <Grid container spacing={2} className={classes.mb3}>
                                <Grid item xs={12} className={classes.mb1}>
                                    <Typography className={classes.h5}>Организация</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="ИНН организации"
                                        variant="outlined"
                                        fullWidth
                                        value={organizationInfo.organizationInn}
                                        onChange={(event) => setOrganizationInfo({...organizationInfo, organizationInn: event.target.value})} />
                                </Grid>
                                <Grid item xs={8}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="outlined-adornment-password">Наименование</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            value={organizationInfo.organizationName}
                                            onChange={(event) => setOrganizationInfo({...organizationInfo, organizationName: event.target.value})}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <CreateIcon color="primary" />
                                                </InputAdornment>
                                            }
                                            labelWidth={122}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className={classes.mb3}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Адрес"
                                        variant="outlined"
                                        fullWidth
                                        value={organizationInfo.organizationAddress}
                                        onChange={(event) => setOrganizationInfo({...organizationInfo, organizationAddress: event.target.value})} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className={classes.mb3}>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Код ОКПО"
                                        variant="outlined"
                                        fullWidth
                                        value={organizationInfo.okpo}
                                        onChange={(event) => setOrganizationInfo({...organizationInfo, okpo: event.target.value})} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label="Код ГНИ"
                                        variant="outlined"
                                        fullWidth
                                        value={organizationInfo.codeOfTaxAuthority}
                                        onChange={(event) => setOrganizationInfo({...organizationInfo, codeOfTaxAuthority: event.target.value})} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Наименование ГНИ"
                                        variant="outlined"
                                        fullWidth
                                        value={organizationInfo.nameOfTaxAuthority}
                                        onChange={(event) => setOrganizationInfo({...organizationInfo, nameOfTaxAuthority: event.target.value})} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className={classes.mb3}>
                                <Grid item xs={12} className={classes.mb1}>
                                    <Typography className={classes.h5}>Банковские реквизиты</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Банк"
                                        variant="outlined"
                                        fullWidth
                                        value={organizationInfo.bank}
                                        onChange={(event) => setOrganizationInfo({...organizationInfo, bank: event.target.value})} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className={classes.mb3}>
                                <Grid item xs={3}>
                                    <TextField
                                        label="БИК"
                                        variant="outlined"
                                        fullWidth
                                        value={organizationInfo.bik}
                                        onChange={(event) => setOrganizationInfo({...organizationInfo, bik: event.target.value})} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Расчетный счет"
                                        variant="outlined"
                                        fullWidth
                                        value={organizationInfo.paymentAccount}
                                        onChange={(event) => setOrganizationInfo({...organizationInfo, paymentAccount: event.target.value})} />
                                </Grid>
                            </Grid>
                            <Divider className={classes.mb5} />
                            <Grid container spacing={2} className={classes.mb5}>
                                <Grid item xs={12}>
                                    <Typography className={classes.h5}>Подключить услуги</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={organizationInfo.accessForDocumentCirculation}
                                                onChange={(event) => setOrganizationInfo({...organizationInfo, accessForDocumentCirculation: event.target.checked})}
                                                value="checkedA"
                                                color="primary"
                                            />
                                        }
                                        label="Электронный документооборот"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={organizationInfo.accessForCreditHistory}
                                                onChange={(event) => setOrganizationInfo({...organizationInfo, accessForCreditHistory: event.target.checked})}
                                                value="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Кредитная история"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} justify="center">
                                <Grid item xs={6}>
                                    <Button variant="outlined" size="large" disableElevation className={classes.cancel} onClick={() => history.goBack()}>
                                        Отмена
                                    </Button>
                                    <Button variant="contained" color="primary" size="large"
                                        disableElevation
                                        onClick={saveRegistration}>
                                        Сохранить
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state: RootState) => ({
    sessionId: state.auth.sessionId,
});

export default connect(mapStateToProps)(PartnerRegistration);
