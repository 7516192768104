import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
import { Grid, Typography, Box, Tabs, Tab } from '@material-ui/core'
import RoundChartIcons from '../../bricks/RoundChartIcons';
import { useStyles } from './HomeStyle'
import MenuDrawer from '../../MenuDrawer/MenuDrawer'
import { AdminAPI, ITotalRequests } from '../../../api/AdminAPI';
import moment from 'moment';
import { Redirect } from 'react-router-dom';

type Props = ReturnType<typeof mapStateToProps>;

const Home:React.FC<Props> = ({ sessionId, getUserInfo, canViewStatistics, canCreateEmployees }) => {
    const classes = useStyles()
    const [value, setValue] = React.useState(1);
    const [foundOrganizations, setFoundOrganizations] = useState(null);
    const [totalRequests, setTotalRequests] = useState<ITotalRequests | null>(null);
    const [date, setDate] = useState({
        dateStart: moment().startOf('month').toDate(),
        dateEnd: moment().endOf('month').toDate(),
    });

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        if(newValue === 0) {
            setDate({
                dateStart: moment().startOf('month').subtract(1, 'months').toDate(),
                dateEnd: moment().startOf('month').toDate(),
            }) 
        } 
        else if(newValue === 1) {
            setDate({
                dateStart: moment().startOf('month').toDate(),
                dateEnd: moment().endOf('month').toDate(),
            }) 
        } else if(newValue === 2) {
            setDate({
                dateStart: moment().startOf('year').toDate(),
                dateEnd: moment().endOf('year').toDate(),
            })
        }
        setValue(newValue);
    };

    useEffect(() => {
        if(sessionId) {
            AdminAPI.searchOrganizations(sessionId, 10, 0).then(data => {
                setFoundOrganizations(data.foundOrganizations);
            });
        }
    }, [sessionId]);

    useEffect(() => {
        if(sessionId) {
            AdminAPI.getRequestStatistics(sessionId, date.dateStart, date.dateEnd).then(data => {
                setTotalRequests(data);
            });
        }
    }, [sessionId, value, date]);


    const percentIncome = () => {
        if(totalRequests) {
            const summ = totalRequests.settlementsService + totalRequests.ishenim;
            return Math.round(totalRequests.settlementsService / summ * 100);
        } else {
            return 0;
        } 
    }

    const percentHistory = () => {
        if(totalRequests) {
            const summ = totalRequests.settlementsService + totalRequests.ishenim;
            return Math.round(totalRequests.ishenim / summ * 100);
        } else {
            return 0;
        } 
    }

    if(
        getUserInfo && 
        !getUserInfo.isIshenim &&
        !getUserInfo.canViewStatistics &&
        getUserInfo.canCreateEmployees
    ) {
        return <Redirect to={`/partners`} />
    }

    return (
        <>
            <MenuDrawer />
            <Grid item xs className="contentGrid">
                <Box mb={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="h4" className={classes.h4}>Главная</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <Grid className={classes.containerBox}>
                            <Grid className={classes.itemBox}>
                                <Box className={classes.boxItem}>
                                    <Box className={classes.headItem}>
                                        <Box className={classes.title}>Партнеры</Box>
                                    </Box>
                                    <Box>
                                        <Box className={classes.boxCount}>{foundOrganizations}</Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid className={classes.itemBox}>
                                <Box className={classes.boxItem} maxWidth="533px">
                                    <Box className={`${classes.headItem} ${classes.mb4}`} justifyContent="space-between">
                                        <Box className={classes.title}>Статистика запросов</Box>
                                        <Tabs value={value} onChange={handleChange} className={classes.tabs}>
                                            <Tab className="monthСapital" label={moment().startOf('month').subtract(1, 'months').format('MMMM')} {...a11yProps(0)} />
                                            <Tab label="Текущий месяц" {...a11yProps(1)} />
                                            <Tab label="2020 год" {...a11yProps(2)} />
                                        </Tabs>
                                    </Box>
                                    <Box className={classes.rowTabPanel}>
                                        <TabPanel value={value} index={0}>
                                            {totalRequests && 
                                                <Box>
                                                    <RoundChartIcons 
                                                        settlementsService={totalRequests.settlementsService}
                                                        ishenim={totalRequests.ishenim} />
                                                </Box>
                                            }
                                            {totalRequests && 
                                                <Box>
                                                    <ul className={classes.ul}>
                                                        <li>
                                                            <span>Информация о доходах</span>
                                                            <strong>{totalRequests.settlementsService} <span className={classes.span}>({percentIncome()}%)</span></strong>
                                                        </li>
                                                        <li className={classes.history}>
                                                            <span>Кредитная история</span>
                                                            <strong>{totalRequests.ishenim} <span className={classes.span}>({percentHistory()}%)</span></strong>
                                                        </li>
                                                    </ul>
                                                </Box>
                                            }
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                            {totalRequests && 
                                                <Box>
                                                    <RoundChartIcons 
                                                        settlementsService={totalRequests.settlementsService}
                                                        ishenim={totalRequests.ishenim} />
                                                </Box>
                                            }
                                            {totalRequests && 
                                                <Box>
                                                    <ul className={classes.ul}>
                                                        <li>
                                                            <span>Информация о доходах</span>
                                                            <strong>{totalRequests.settlementsService} <span className={classes.span}>({percentIncome()}%)</span></strong>
                                                        </li>
                                                        <li className={classes.history}>
                                                            <span>Кредитная история</span>
                                                            <strong>{totalRequests.ishenim} <span className={classes.span}>({percentHistory()}%)</span></strong>
                                                        </li>
                                                    </ul>
                                                </Box>
                                            }
                                        </TabPanel>
                                        <TabPanel value={value} index={2}>
                                            {totalRequests && 
                                                <Box>
                                                    <RoundChartIcons 
                                                        settlementsService={totalRequests.settlementsService}
                                                        ishenim={totalRequests.ishenim} />
                                                </Box>
                                            }
                                            {totalRequests && 
                                                <Box>
                                                    <ul className={classes.ul}>
                                                        <li>
                                                            <span>Информация о доходах</span>
                                                            <strong>{totalRequests.settlementsService} <span className={classes.span}>({percentIncome()}%)</span></strong>
                                                        </li>
                                                        <li className={classes.history}>
                                                            <span>Кредитная история</span>
                                                            <strong>{totalRequests.ishenim} <span className={classes.span}>({percentHistory()}%)</span></strong>
                                                        </li>
                                                    </ul>
                                                </Box>
                                            }
                                        </TabPanel>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
	sessionId: state.auth.sessionId,
	canViewStatistics: state.auth.getUserInfo?.canViewStatistics,
	canCreateEmployees: state.auth.getUserInfo?.canCreateEmployees,
	getUserInfo: state.auth.getUserInfo
});

export default connect(mapStateToProps)(Home);

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
  
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            >
            {value === index && <Box p={3}>{children}</Box>}
            </Typography>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}