import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Select, MenuItem } from '@material-ui/core'

const YearSelect = () => {
    const classes = useStyles()
    const [year, setYear] = React.useState('2020')

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setYear(event.target.value as string);
    };


    return (
        <Select
            className={classes.root}
            value={year}
            onChange={handleChange} 
            margin="dense"
            variant="outlined">
            <MenuItem value='2020'>2020</MenuItem>
            <MenuItem value='2019'>2019</MenuItem>
            <MenuItem value='2018'>2018</MenuItem>
            <MenuItem value='2017'>2017</MenuItem>
            <MenuItem value='2016'>2016</MenuItem>
        </Select>
    )
}

export default YearSelect

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 112,
      textAlign: 'left',
      fontWeight: 600,
      borderRadius: '0 4px 4px 0',
      '& .MuiSelect-select': {
        paddingLeft: 8
      }
    }
  })
)