import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Grid, Box, TextField } from '@material-ui/core'
import personImg from '../../images/person.jpg'
import passportAfter from '../../images/passportAfter.jpg'
import passportBefore from '../../images/passportBefore.jpg'
import './ClientDetail.scss'

 const ClientDetail = () => {
  const classes = useStyles()
  const client = window.location.pathname === '/clientInfo' || window.location.pathname === '/information'

  return (
    <Grid container>
        {!(client) &&
            <Grid item xs={2}></Grid>
        }
        <Grid item xs={client ? 10 : 9} >
            <div className="personCard">
                <div className="personCard__item">
                    <img src={personImg} alt="person" />
                    <Box className="personalNumberBox">
                        <TextField 
                            label="Номер телефона" 
                            variant="outlined" 
                            fullWidth 
                            value="996 555 12 02 19" />
                    </Box>
                </div>
                <div className="personCard__item">
                    <div className="personBox">
                        <div className="personBox__item">
                            <Box className={classes.box}>
                                <Typography className={classes.strong}>Персональный номер</Typography>
                                <Typography>23011198400286</Typography>
                            </Box>
                            <Box className={classes.box}>
                                <Typography className={classes.strong}>Паспорт серия №</Typography>
                                <Typography>AN 189 9867</Typography>
                            </Box>
                            <Box className={classes.box}>
                                <Typography className={classes.strong}>Дата рождения</Typography>
                                <Typography>20.08.1979</Typography>
                            </Box>
                            <Box className={classes.box}>
                                <Typography className={classes.strong}>Семейное положение</Typography>
                                <Typography className={classes.married}>Женат</Typography>
                            </Box>
                            <Box mt={1}>
                                <img src={passportBefore} alt="password" />
                            </Box>
                        </div>
                        <div className="personBox__item">
                            <Box className={classes.box}>
                                <Typography className={classes.strong}>Дата выдачи паспорта</Typography>
                                <Typography>20.08.2016</Typography>
                            </Box>
                            <Box className={classes.box}>
                                <Typography className={classes.strong}>Орган, выдавший паспорт</Typography>
                                <Typography>ИММ 50-05</Typography>
                            </Box>
                            <Box className={classes.box}>
                                <Typography className={classes.strong}>Срок окончания действия паспорта</Typography>
                                <Typography>20.08.2026</Typography>
                            </Box>
                            <Box className={classes.box}>
                                <Typography className={classes.strong}>Адрес проживания</Typography>
                                <Typography>Бишкек, ул. Фрунзе, д. 232 кв. 12 <br /> Акталинский р-н, село Кара-Буура</Typography>
                            </Box>
                            <Box mt={1}>
                                <img src={passportAfter} alt="password" />
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </Grid>
    </Grid>
  )
}

export default ClientDetail

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    strong: {
        fontWeight: 700
    },
    box: {
        marginBottom: 12
    },
    married: {
        color: '#FA7800',
        fontWeight: 'bold'
    }
  })
)