import React from 'react'
import { Grid, Typography, Box } from '@material-ui/core'
import Paginations from '../../Paginatins/Paginations'
import { useStyles } from './RequestStyle'
import RequestTable from '../../bricks/RequestTable'
import MenuDrawer from '../../MenuDrawer/MenuDrawer'


const Request = () => {
    const classes = useStyles()

    return (
        <>
            <MenuDrawer />
            <Grid item xs className="contentGrid">
                <Box mb={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4" className={classes.h4}>Заявки клиентов</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <RequestTable />
                    </Grid>
                    <Grid item xs={12} className={classes.center}>
                        <Paginations count={10} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Request