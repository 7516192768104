import { combineReducers } from 'redux';
import auth from './Auth/reducer';
import admin from './Admin/reducer';

const rootReducer = combineReducers({
    auth,
    admin,
});


export default rootReducer;