import axios from 'axios';
import {creditBureauUrl} from '../utils/baseUrl';
import {IGetEmployee} from '../store/Admin/types';

const instance = axios.create({
    baseURL: `${creditBureauUrl()}api/Admin/`,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "apiKey": "DE57A23D-D449-4279-A2FB-14A3C5F650F2",
    }
})

export const AdminAPI = {
    async searchOrganizations(sessionId: string, maxShowRequest: number, skipRequest: number) {
        return await instance.post(`SearchOrganizations/${sessionId}`, {maxShowRequest, skipRequest}).then(res => {
            return res.data;
        });
    },

    async searchOrganizationEmployees(sessionId: string, maxShowRequest: number, skipRequest: number, creditOrganizationId: number, employeeName: string | null) {
        return await instance.post(`SearchOrganizationEmployees/${sessionId}`, {
            maxShowRequest,
            skipRequest,
            creditOrganizationId,
            employeeName
        }).then(res => {
            return res.data;
        });
    },

    async searchOrganizationCustomers(sessionId: string, maxShowRequest: number, skipRequest: number, creditOrganizationId: number,
                                      dateFrom: Date, dateTo: Date, customerName: string | null, employeeId: number | null) {
        return await instance.post(`SearchOrganizationCustomers/${sessionId}`, {
            maxShowRequest,
            skipRequest,
            creditOrganizationId,
            dateFrom,
            dateTo,
            customerName,
            employeeId
        }).then(res => {
            return res.data;
        });
    },

    async getOrganizationsStatistics(sessionId: string, maxShowRequest: number, skipRequest: number, dateFrom: Date, dateTo: Date) {
        return await instance.post(`GetOrganizationsStatistics/${sessionId}`, {
            maxShowRequest,
            skipRequest,
            dateFrom,
            dateTo
        }).then(res => {
            return res.data;
        });
    },
    async getRequestStatistics(sessionId: string, dateFrom: Date | null = null, dateTo: Date | null = null,
                               creditOrganizationId: number | null = null, employeeId: number | null = null, customerId: number | null = null) {
        return await instance.post(`GetRequestStatistics/${sessionId}`, {
            dateFrom,
            dateTo,
            creditOrganizationId,
            employeeId,
            customerId
        }).then(res => {
            return res.data;
        });
    },
    async getOrganizationInfo(sessionId: string, organizationId: number) {
        return await instance.get(`GetOrganizationInfo/${sessionId}/${organizationId}`).then(res => {
            return res.data;
        });
    },
    async setOrganizationInfo(sessionId: string, organizationInfo: IGetOrganizationInfo) {
        return await instance.post(`SetOrganizationInfo/${sessionId}`, organizationInfo).then(res => {
            return res.data;
        });
    },
    async getOrganizationEmployees(sessionId: string, creditOrganizationId: number) {
        return await instance.get(`GetOrganizationEmployees/${sessionId}/${creditOrganizationId}`).then(res => {
            return res.data;
        });
    },
    async getEmployee(sessionId: string, employeeId: number) {
        return await instance.get(`GetEmployee/${sessionId}/${employeeId}`).then(res => {
            return res.data;
        });
    },
    async setEmployee(sessionId: string, employeeInfo: IGetEmployee) {
        return await instance.post(`SetEmployee/${sessionId}`, employeeInfo).then(res => {
            return res.data;
        });
    },
    async deleteEmployee(sessionId: string, employeeId: number) {
        return await instance.post(`DeleteEmployee/${sessionId}/${employeeId}`).then(res => {
            return res.data;
        });
    },
}

export interface ISearchCustomers {
    maxShowRequest: number,
    skipRequest: number,
    creditOrganizationId: number,
    dateFrom: Date,
    dateTo: Date,
    customerName: string | null,
    employeeId: number | null
}

export interface IGetOrganizationInfo {
    okpo: string,
    codeOfTaxAuthority: string,
    nameOfTaxAuthority: string,
    organizationAddress: string,
    bank: string,
    bik: string,
    paymentAccount: string,
    accessForDocumentCirculation: boolean,
    accessForCreditHistory: boolean,
    creditOrganizationId: number,
    organizationName: string,
    organizationInn: string,
}

export interface ITotalRequests {
    ishenim: number,
    settlementsService: number,
    countRegistrations: number
}

export interface IOrganizations {
    creditOrganizationId: number,
    organizationName: string,
    organizationInn: string
}

export interface IOrganizationStatistics {
    creditOrganizationId: number,
    organizationName: string,
    organizationInn: string,
    ishenimRequests: number,
    settlementsService: number,
    countRegistrations: number
}

export interface IEmployees {
    creditOrganizationId: number,
    employeeId: number,
    employeeName: string,
    employeeInn: string,
}

export interface ICustomers {
    customerId: number,
    customerInn: string,
    customerName: string,
    creditOrganizationId: number,
    registrationEmployeeId: number,
    registrationEmployeeName: string,
    registrationEmployeeInn: string,
    registrationDate: Date,
}
