import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { NavLink, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles'
  
function createData(name: string, type: string, rate: string, term: string) {
    return { name, type, rate, term }
}

const contracts = [
    createData('Потребительский кредит “Мини”', 'Беззалоговый', '16,5 %', '3 - 12 месяцев'),
    createData('Потребительский кредит “Макси”', 'Беззалоговый', '16,5 %', '3 - 12 месяцев'),
    createData('Потребительский кредит “Технодом”', 'Беззалоговый', '16,5 %', '3 - 12 месяцев'),
    createData('Потребительский кредит “Пенсионный”', 'Беззалоговый', '16,5 %', '3 - 12 месяцев'),
]

const ContractsTable = () => {
    const classes = useStyles()

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell align="left">№</TableCell>
                    <TableCell>Наименование</TableCell>
                    <TableCell align="left">Тип</TableCell>
                    <TableCell align="center">Процентная ставка</TableCell>
                    <TableCell align="left">Срок</TableCell>
                    <TableCell align="left"></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {contracts.map((contract, index) => (
                        <TableRow key={index}>
                            <TableCell align="left"><strong className={classes.strong}>{index + 1}</strong></TableCell>
                            <TableCell component="th" scope="row">
                                {contract.name}
                            </TableCell>
                            <TableCell align="left">{contract.type}</TableCell>
                            <TableCell align="center">{contract.rate}</TableCell>
                            <TableCell align="left">{contract.term}</TableCell>
                            <TableCell align="center">
                                <NavLink to="/contracts">Договор</NavLink>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default withRouter(ContractsTable)

const useStyles = makeStyles(theme => ({
    strong: {
        fontWeight: 600
    }
}))