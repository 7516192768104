import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
import { Grid, Typography, Box, Button } from '@material-ui/core'
import { useStyles } from './StatisticsStyle'
import StatisticsTable from '../../bricks/StatisticsTable'
import DateRange from '../../bricks/DateRange'
import { IOrganizationStatistics, ITotalRequests, AdminAPI } from '../../../api/AdminAPI'
import Pagination from '@material-ui/lab/Pagination';
import MenuDrawer from '../../MenuDrawer/MenuDrawer';
import { ExcelApi } from '../../../api/ExcelApi';

type Props = ReturnType<typeof mapStateToProps>;

const Statistics:React.FC<Props> = (props) => {
    const { sessionId, startDate, endDate, getUserInfo } = props;

    const classes = useStyles();
    const [organizations, setOrganizations] = useState<IOrganizationStatistics[]>([]);
    const [foundOrganizations, setFoundOrganizations] = useState(0);
    const [page, setPage] = React.useState(1);
    const limit = 10;
    const min = (page -1) * limit;
    const [totalRequests, setTotalRequests] = useState<ITotalRequests | null>(null);

    useEffect(() => {
        if(sessionId) {
            AdminAPI.getOrganizationsStatistics(sessionId, limit, min, startDate, endDate).then(data => {
                setFoundOrganizations(data.foundOrganizations);
                setOrganizations(data.organizations);
            })
        }
    }, [sessionId, page, min, startDate, endDate]);

    useEffect(() => {
        if(sessionId) {
            AdminAPI.getRequestStatistics(sessionId, startDate, endDate).then(data => {
                if(data.ishenim > 0) {
                    setTotalRequests(data)
                }
            })
        }
    }, [sessionId, startDate, endDate]);

    const paginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const downloadExelOrganizations = () => {
        if(sessionId) {
            ExcelApi.getOrganizationsStatistics(sessionId, startDate, endDate).then(data => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Статистика.xlsx');
                document.body.appendChild(link);
                link.click();
            });
        }
    }

    let currentCount = (foundOrganizations - (foundOrganizations % limit)) / limit;
    if (foundOrganizations % limit > 0) {
        currentCount = currentCount + 1;
    }

    const newOrganizations = () => {
        if(getUserInfo && getUserInfo.isIshenim) {
            return organizations;
        } else {
            const filterOrganizations = getUserInfo ? organizations.filter(organization => organization.creditOrganizationId === getUserInfo.organizationId) : organizations;
            return filterOrganizations;
        }
    };
    return (
        <>
            <MenuDrawer />
            <Grid item xs className="contentGrid">
                <Box mb={3}>
                    <Grid container spacing={2} justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="h4" className={classes.h4}>Статистика</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Button variant="contained" color="primary" size="large"
                                        disableElevation
                                        onClick={downloadExelOrganizations}>
                                        Cкачать excel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <DateRange />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <StatisticsTable organizations={newOrganizations()} />
                    </Grid>
                    {totalRequests &&
                        <Grid item xs={12}>
                            <Box mb={3}>
                                <Typography variant="h5" align="right" className={classes.h5}>Итого запросов <span className="line">|</span>Идентификация: {totalRequests.countRegistrations}  <span className="line">|</span> Ишеним: {totalRequests.ishenim} <span className="line">|</span> Доходы: {totalRequests.settlementsService}</Typography>
                            </Box>
                        </Grid>
                    }
                    <Grid item xs={12} className={classes.center}>
                        {foundOrganizations > 10 &&
                            <Pagination count={currentCount} page={page} onChange={paginationChange} shape="rounded" className={classes.root} />
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};

const mapStateToProps = (state: RootState) => ({
    sessionId: state.auth.sessionId,
    startDate: state.admin.startDate,
    endDate: state.admin.endDate,
    getUserInfo: state.auth.getUserInfo,
});

export default connect(mapStateToProps)(Statistics);
