import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../store'
import { Grid, Typography, Button, Box } from '@material-ui/core'
import PartnersTable from '../../bricks/PartnersTable'
import { useStyles } from './PartnersStyle'
import { NavLink } from 'react-router-dom'
import { AdminAPI, IOrganizations } from '../../../api/AdminAPI'
import Pagination from '@material-ui/lab/Pagination'
import MenuDrawer from '../../MenuDrawer/MenuDrawer'

type Props = ReturnType<typeof mapStateToProps>

const Partners: React.FC<Props> = ({ sessionId, getUserInfo }) => {
    const classes = useStyles()
    const [organizations, setOrganizations] = useState<IOrganizations[]>([])
    const [foundOrganizations, setFoundOrganizations] = useState(0)
    const [page, setPage] = React.useState(1)
    const limit = 10
    const min = (page - 1) * limit

    useEffect(() => {
        if (sessionId) {
            AdminAPI.searchOrganizations(sessionId, limit, min).then((data) => {
                setFoundOrganizations(data.foundOrganizations)
                setOrganizations(data.organizations)
            })
        }
    }, [sessionId, page, min])

    const paginationChange = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setPage(value)
    }

    let currentCount =
        (foundOrganizations - (foundOrganizations % limit)) / limit
    if (foundOrganizations % limit > 0) {
        currentCount = currentCount + 1
    }

    const newOrganizations = () => {
        if (getUserInfo && getUserInfo.isIshenim) {
            return organizations
        } else if (
            getUserInfo &&
            getUserInfo.canCreateEmployees &&
            !getUserInfo.canViewStatistics
        ) {
            const filteredOrganizations = getUserInfo
                ? organizations.filter((organization) => {
                      return (
                          organization.creditOrganizationId ===
                          getUserInfo.organizationId
                      )
                  })
                : organizations

            return filteredOrganizations
        } else {
            const filterOrganizations = getUserInfo
                ? organizations.filter(
                      (organization) =>
                          organization.creditOrganizationId ===
                          getUserInfo.organizationId
                  )
                : organizations
            return filterOrganizations
        }
    }

    return (
        <>
            <MenuDrawer />
            <Grid item xs className="contentGrid">
                <Box mb={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="h4" className={classes.h4}>
                                Партнеры
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align="right">
                                {(getUserInfo && getUserInfo.canCreateEmployees && getUserInfo.isIshenim) && ( 
                                    <NavLink to="/partnerRegistration">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            disableElevation
                                        >
                                            Новый партнер
                                        </Button>
                                    </NavLink>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <PartnersTable organizations={newOrganizations()} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box mb={3}>
                            <Typography
                                variant="h5"
                                align="right"
                                className={classes.h5}
                            >
                                Всего: {foundOrganizations}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className={classes.center}>
                        {foundOrganizations > 10 && (
                            <Pagination
                                count={currentCount}
                                page={page}
                                onChange={paginationChange}
                                shape="rounded"
                                className={classes.root}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    sessionId: state.auth.sessionId,
    getUserInfo: state.auth.getUserInfo,
})

export default connect(mapStateToProps)(Partners)
