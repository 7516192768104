import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link } from '@material-ui/core';
  
  function createData(organization: string, INN: string, position: string) {
    return { organization, INN, position };
  }
  
  const staff = [
    createData('Абдышев Азамат Нурланович', '23011198400286', 'Администратор'),
    createData('Абдышев Азамат Нурланович', '23011198400286', 'Кредитный специалист'),
    createData('Абдышев Азамат Нурланович', '23011198400286', 'Менеджер'),
    createData('Абдышев Азамат Нурланович', '23011198400286', 'Кредитный специалист'),
    createData('Абдышев Азамат Нурланович', '23011198400286', 'Кредитный специалист'),
    createData('Абдышев Азамат Нурланович', '23011198400286', 'Администратор'),
    createData('Абдышев Азамат Нурланович', '23011198400286', 'Директор'),
    createData('Абдышев Азамат Нурланович', '23011198400286', 'Администратор'),
    createData('Абдышев Азамат Нурланович', '23011198400286', 'Администратор'),
    createData('Абдышев Азамат Нурланович', '23011198400286', 'Администратор'),
    createData('Абдышев Азамат Нурланович', '23011198400286', 'Администратор')
  ];

const PartnerStaffTable = () => {

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">№</TableCell>
                        <TableCell>Сотрудник</TableCell>
                        <TableCell align="left">ИНН</TableCell>
                        <TableCell align="left">Должность</TableCell>
                        <TableCell align="center">Действие</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {staff.map((employee, index) => (
                    <TableRow key={index}>
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell component="th" scope="row">{employee.organization}</TableCell>
                        <TableCell component="th" align="left">ИНН {employee.INN}</TableCell>
                        <TableCell component="th" align="left">{employee.position}</TableCell>
                        <TableCell align="center">
                            <Link href="#">Открыть</Link>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default PartnerStaffTable