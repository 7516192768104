import { makeStyles } from '@material-ui/core/styles'
const defaultColor ='#83D170';

const useStyles = makeStyles(theme => ({
    h4: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
        fontSize: 32
    },
    h5: {
        fontWeight: 600,
        fontSize: 16
    }, 
    center: {
        textAlign: 'center'
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        '& .MuiButtonBase-root': {
            background: '#FFFFFF',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: 4,
            color: '#333333',
            fontSize: 16,
            fontWeight: 700,
            minWidth: 34,
            height: 34,
            '&:hover': {
                backgroundColor: defaultColor,
                color: '#ffffff',
                '& .MuiPaginationItem-icon': {
                    color: '#fff'
                }
            },
            '&.Mui-selected': {
                backgroundColor: defaultColor,
                color: '#ffffff'
            },
            '& .MuiPaginationItem-icon': {
                fontSize: 32,
                color: '#787878'
            },
            '&.Mui-disabled': {
                opacity: 0.7
            }
        }
    }
}))

export { useStyles }