import React from 'react'
import { Grid, Typography, Box, Button } from '@material-ui/core'
import { useStyles } from './LoanAgreementStyle'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useHistory } from 'react-router-dom'
import AgreementTable from '../../bricks/AgreementTable'

const LoanAgreement = () => {
    const classes = useStyles()
    let history = useHistory()

    return (
        <Grid item xs className="contentGrid">
            <Box mb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Button 
                            variant="contained" 
                            color="inherit" 
                            size="large" 
                            disableElevation
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.goBack()}>
                            Назад
                        </Button>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="h4" className={classes.h4}>Договор займа</Typography>
                        <Box>
                            <Typography variant="h5" className={classes.h6}>Абдышева Асия Нурлановна</Typography>
                            <Typography variant="h5" className={classes.h6}>ИНН: 13011198400286</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                    <Grid className={classes.container}>
                        <Grid className={classes.containerItem}>
                            <Box className={classes.boxShadow}>
                                <Typography variant="h5" className={`${classes.h5} ${classes.mb3}`}>Условия по кредиту</Typography>
                                <Box className={classes.boxWrap}>
                                    <Box className={classes.boxWrapItem}>
                                        <span>Сумма</span>
                                        <strong>50 000 сом</strong>
                                    </Box>
                                    <Box className={classes.boxWrapItem}>
                                        <span>Срок</span>
                                        <strong>6 мес.</strong>
                                    </Box>
                                    <Box className={classes.boxWrapItem}>
                                        <span>Ставка в год</span>
                                        <strong>16,5 %</strong>
                                    </Box>
                                    <Box className={classes.boxWrapItem}>
                                        <span>Платеж, месяц</span>
                                        <strong>9 000 сом</strong>
                                    </Box>
                                    <Box className={classes.boxWrapItem}>
                                        <span>Залог</span>
                                        <strong>Не требуется</strong>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid className={`${classes.containerItem} ${classes.containerItemRight}`}>
                            <Box className={classes.boxShadow}>
                                <Typography variant="h5" className={`${classes.h5} ${classes.mb3}`}>Сведения</Typography>
                                <Box className={classes.boxWrap}>
                                    <Box className={classes.boxWrapItem} width="100%">
                                        <span>Тип заемщика</span>
                                        <strong>Физическое лицо</strong>
                                    </Box>
                                    <Box className={classes.boxWrapItem} width="100%">
                                        <span>Цель кредита</span>
                                        <strong>На юбилей</strong>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box mb={3} mt={3}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="h4" className={classes.h5}>Документы</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <AgreementTable />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default LoanAgreement
