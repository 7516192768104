import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import { ICustomers } from '../../api/AdminAPI';
import { Button } from '@material-ui/core';
import Moment from 'react-moment';
import 'moment/locale/ru'
  
type Props = {
    customers: ICustomers[];
    creditOrganizationId: number;
}

const OrganizationsTable:React.FC<Props> = ({ customers, creditOrganizationId }) => {
    let history = useHistory();
    
    const customerClick = (customerId: number) => {
        return history.push(`/statistics/${creditOrganizationId}/${customerId}`);
    }

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell align="left">№</TableCell>
                    <TableCell>Клиент</TableCell>
                    <TableCell align="left">Сотрудник</TableCell>
                    <TableCell align="center">Дата регистрации</TableCell>
                    <TableCell align="center">Действие</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {customers.map((customer, index) => (
                    <TableRow key={index}>
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell component="th" scope="row">
                            {customer.customerName}
                            <div>ИНН: {customer.customerInn}</div>
                        </TableCell>
                        <TableCell align="left">
                            {customer.registrationEmployeeName}
                            <div>ИНН: {customer.registrationEmployeeInn}</div>
                        </TableCell>
                        <TableCell align="center"><Moment format="D MMMM YYYY">{customer.registrationDate}</Moment></TableCell>
                        <TableCell align="center">
                            <Button className="tableLinkBlue" onClick={() => customerClick(customer.customerId)}>Открыть</Button>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default OrganizationsTable