export const baseUrl = () => {
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "verify.onoi.kg") {
        return 'https://smartidkg.onoi.kg/';
    } else {
        return `https://smartidkg.onoi.kg/`
    }
}

export const creditBureauUrl = () => {
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "verify.onoi.kg") {
        return 'https://creditbureau.onoi.kg/';
    } else {
        return `https://creditbureau.onoi.kg/`
    }
}