import React, { Dispatch, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { AdminAPI, IEmployees } from '../../api/AdminAPI';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & {
    creditOrganizationId: number;
}

const AllEmployees:React.FC<Props> = ({ sessionId, creditOrganizationId, employeeIdAction }) => {
    const [employees, setEmployees] = useState<IEmployees[]>([]);
    const [value, setValue] = useState<IEmployees | null>(null);

    const handleChange = (newValue: IEmployees | null) => {
        setValue(newValue);
        if(newValue) {
            employeeIdAction(newValue.employeeId)
        } else {
            employeeIdAction(null)
        }
    }

    useEffect(() => {
        if(sessionId) {
            AdminAPI.getOrganizationEmployees(sessionId, creditOrganizationId).then(data => {
                setEmployees(data);
            })
        }
    }, [sessionId, creditOrganizationId]);
    
    return (
        <Autocomplete
            options={employees}
            getOptionLabel={(option: IEmployees) => option.employeeName}
            fullWidth
            size="small"
            className="autocomplate"
            value={value}
            onChange={(event: React.ChangeEvent<{}>, newValue: IEmployees | null) => handleChange(newValue)}
            renderInput={(params) => 
                <TextField {...params} 
                    variant="outlined"
                    label="Все сотрудники"
                    size="small" />
            }
        />
    )
}

const mapStateToProps = (state: RootState) => ({
    sessionId: state.auth.sessionId,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    employeeIdAction: (employeeId: number | null) => dispatch(actions.admin.employeeIdAction(employeeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllEmployees);