import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import Header from '../Header/Header'
import { mainTheme, useStyles } from './MainTheme'
import './App.scss'
import store from '../../store';
import AppStack from '../AppStack/AppStack';

const App = () => {
    const classes = useStyles();

    return (
        <Provider store={store}>
            <MuiThemeProvider theme={mainTheme}>
                <Header />
                <Grid container className={classes.rootGrid}>
                    <Switch>
                        <Route path="/" component={AppStack} />
                    </Switch>
                </Grid>
            </MuiThemeProvider>
        </Provider>
    )
}

export default withRouter(App);