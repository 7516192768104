import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'
  
  function createData(name: string, date: string) {
    return { name, date }
  }
  
  const documents = [
    createData('Кредитная история', '21 октября 2019'),
    createData('Справка о доходах', '12 октября 2019'),
    createData('Справка о патентах', '11 октября 2019'),
    createData('Поручительство', '09 октября 2019'),
    createData('Согласие супруга', '05 октября 2019'),
    createData('Договор займа', '01 октября 2019'),
  ];

const ClientDocumentTable = () => {
    const classes = useStyles()

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell align="left">№</TableCell>
                    <TableCell>Документ</TableCell>
                    <TableCell align="center">Дата содания</TableCell>
                    <TableCell align="center">Просмотреть</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {documents.map((document, index) => (
                    <TableRow key={index}>
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell component="th" scope="row">{document.name}</TableCell>
                        <TableCell align="center">{document.date}</TableCell>
                        <TableCell align="center">
                            <NavLink className={classes.link} to="#">Документ</NavLink>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ClientDocumentTable

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'none'
        }
    }
  })
)