import React from 'react'
import { Grid, Typography, Box, Button, TextField, 
         FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { useStyles } from './NewContractStyle'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useHistory, NavLink } from 'react-router-dom'


const NewContract = () => {
    const classes = useStyles()
    let history = useHistory()

    const [position, setPosition] = React.useState('Физическое лицо')
    const [сurrency, setСurrency] = React.useState('Сом')
    const [pledge, setPledge] = React.useState('Не требуется')

    const inputLabelPosition = React.useRef<HTMLLabelElement>(null)
    const [labelWidthPosition, setLabelWidthPosition] = React.useState(0)

    const inputLabelСurrency = React.useRef<HTMLLabelElement>(null)
    const [labelWidthСurrency, setLabelWidthСurrency] = React.useState(0)

    const inputLabelPledge = React.useRef<HTMLLabelElement>(null)
    const [labelWidthPledge, setLabelWidthPledge] = React.useState(0)

    React.useEffect(() => {
        setLabelWidthPosition(inputLabelPosition.current!.offsetWidth)
        setLabelWidthPledge(inputLabelPledge.current!.offsetWidth)
        setLabelWidthСurrency(inputLabelСurrency.current!.offsetWidth)
    }, [])

    const positionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPosition(event.target.value as string)
    }

    const currencyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setСurrency(event.target.value as string)
    }

    const pledgeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPledge(event.target.value as string)
    }

    return (
        <Grid item xs className="contentGrid">
            <Box mb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Button 
                            variant="contained" 
                            color="inherit" 
                            size="large" 
                            disableElevation
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.goBack()}>
                            Назад
                        </Button>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h4" className={classes.h4}>Заявка “Договор займа”</Typography>
                        <Box>
                            <Typography variant="h5" className={classes.h6}>Абдышева Асия Нурлановна</Typography>
                            <Typography variant="h5" className={classes.h6}>ИНН: 13011198400286</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                    <form className={classes.root} noValidate autoComplete="off">
                        <Grid container spacing={2} className={classes.mb3}>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" fullWidth className={classes.mb1}>
                                    <InputLabel ref={inputLabelPosition}>
                                        Тип заемщика
                                    </InputLabel>
                                    <Select
                                        value={position}
                                        onChange={positionChange}
                                        labelWidth={labelWidthPosition}>
                                        <MenuItem value='Физическое лицо'>Физическое лицо</MenuItem>
                                        <MenuItem value='Юридическое лицо'>Юридическое лицо</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField 
                                    label="Цель кредита" 
                                    variant="outlined" 
                                    fullWidth 
                                    value="На юбилей" />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className={classes.mb3}>
                            <Grid item xs={12} className={classes.mb1}>
                                <Typography className={classes.h5}>Условия по кредиту</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField 
                                    label="Сумма" 
                                    variant="outlined" 
                                    fullWidth 
                                    value="50 000" 
                                    className="textInputRight"/>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel ref={inputLabelСurrency}>
                                        Валюта кредита
                                    </InputLabel>
                                    <Select
                                        value={сurrency}
                                        onChange={currencyChange}
                                        labelWidth={labelWidthСurrency}>
                                        <MenuItem value='Сом'>Сом</MenuItem>
                                        <MenuItem value='Доллар'>Доллар</MenuItem>
                                        <MenuItem value='Евро'>Евро</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel ref={inputLabelPledge}>
                                        Наличие залога
                                    </InputLabel>
                                    <Select
                                        value={pledge}
                                        onChange={pledgeChange}
                                        labelWidth={labelWidthPledge}>
                                        <MenuItem value='Не требуется'>Не требуется</MenuItem>
                                        <MenuItem value='Требуется'>Требуется</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className={classes.mb3}>
                            <Grid item xs={2}>
                                <TextField 
                                    label="Срок, месяцев" 
                                    variant="outlined" 
                                    fullWidth 
                                    value="6" 
                                    className="textInputRight"/>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField 
                                    label="Ставка в год" 
                                    variant="outlined" 
                                    fullWidth 
                                    value="16,5" 
                                    className="textInputRight"/>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField 
                                    label="Платеж, месяц" 
                                    variant="outlined" 
                                    fullWidth 
                                    value="9 000" 
                                    className="textInputRight"/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className={classes.mb3}>
                            <Grid item xs={12} className={classes.mb1}>
                                <Typography className={classes.h5}>Супруг <span style={{color: 'red'}}>*</span></Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField 
                                    label="ИНН супруга" 
                                    variant="outlined" 
                                    fullWidth 
                                    value="12412452454245" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField 
                                    label="ФИО поручителя" 
                                    variant="outlined" 
                                    fullWidth 
                                    value="Aзаматов Азамат Азаматович" />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField 
                                    label="Телефон" 
                                    variant="outlined" 
                                    fullWidth 
                                    value="0555 55 66 88" />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className={classes.mb3}>
                            <Grid item xs={12} className={classes.mb1}>
                                <Typography className={classes.h5}>Поручители</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField 
                                    label="ИНН супруга" 
                                    variant="outlined" 
                                    fullWidth 
                                    value="12412452454245" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField 
                                    label="ФИО поручителя" 
                                    variant="outlined" 
                                    fullWidth 
                                    value="Aзаматов Азамат Азаматович" />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField 
                                    label="Телефон" 
                                    variant="outlined" 
                                    fullWidth 
                                    value="0555 55 66 88" />
                            </Grid>
                            <Grid item xs={12} className={classes.mb2}>
                                <Box className={classes.addGuarantor}>+ Добавить поручителя</Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={6}>
                                <Button variant="outlined" size="large" disableElevation className={classes.cancel}>
                                    Отмена
                                </Button>
                                <NavLink to="/loanAgreement">
                                    <Button variant="contained" color="primary" size="large" disableElevation>
                                        Оформить
                                    </Button>
                                </NavLink>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default NewContract
