import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Select, MenuItem } from '@material-ui/core'

const MonthSelect = () => {
    const classes = useStyles()
    const [month, setMonth] = React.useState('07')

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setMonth(event.target.value as string);
    };


    return (
        <Select
            className={classes.root}
            value={month}
            onChange={handleChange} 
            margin="dense"
            variant="outlined">
            <MenuItem value='01'>Январь</MenuItem>
            <MenuItem value='02'>Февраль</MenuItem>
            <MenuItem value='03'>Март</MenuItem>
            <MenuItem value='04'>Апрель</MenuItem>
            <MenuItem value='05'>Май</MenuItem>
            <MenuItem value='06'>Июнь</MenuItem>
            <MenuItem value='07'>Июль</MenuItem>
            <MenuItem value='08'>Август</MenuItem>
            <MenuItem value='09'>Сентябрь</MenuItem>
            <MenuItem value='10'>Октябрь</MenuItem>
            <MenuItem value='11'>Ноябрь</MenuItem>
            <MenuItem value='12'>Декабрь</MenuItem>
        </Select>
    )
}

export default MonthSelect

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 112,
      textAlign: 'left',
      fontWeight: 600,
      borderRadius: '4px 0 0 4px',
      marginRight: -1,
      '& .MuiSelect-select': {
        paddingLeft: 8
      }
    }
  })
)