import React from 'react'
import { Grid, Typography, Box, Button, TextField } from '@material-ui/core'
import { useStyles } from './NewClientCompletedStyle'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { NavLink, withRouter, useHistory } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'
import ClientDetail from '../../ClientDetail/ClientDetail'


const NewClientCompleted = () => {
    const classes = useStyles()
    let history = useHistory()

    return (
        <Grid item xs className="contentGrid">
            <Box mb={2}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                        <Button 
                            variant="contained" 
                            color="inherit" 
                            size="large" 
                            disableElevation
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.goBack()}>
                            Назад
                        </Button>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography variant="h4" className={classes.h4}>Абдышева Асия Нурлановна</Typography>
                    </Grid>
                    <Grid item xs={1} className={classes.right}>
                        <NavLink to="/specialist">
                            <CloseIcon className={classes.close} />
                        </NavLink>
                    </Grid>
                </Grid>
            </Box>
            <ClientDetail />
            <Grid container spacing={2} justify="center" className={classes.confirmation}>
                <Grid item xs={12}>
                    <Typography component="h5" className={classes.h5} align="center">
                        Подтверждение регистрации клиентом
                    </Typography>
                    <Typography align="center" className={classes.confirmationDesc}>Клиент должен подтвердить регистрацию смс-сообщением</Typography>
                </Grid>
                <Box className={classes.confirmationBox}>
                    <TextField 
                        label="Введите ПИН-код" 
                        variant="outlined" 
                        fullWidth 
                        value="1195" />
                    <NavLink to="/clientInfo">
                        <Button variant="contained" color="primary" size="large" disableElevation>
                            Зарегистрировать
                        </Button>
                    </NavLink>
                </Box>
            </Grid>
        </Grid>
    )
}

export default withRouter(NewClientCompleted)