import React, { Dispatch, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { Route, Switch } from 'react-router-dom'
import { Home, Partners, Statistics, Сustomers, Organizations, Person, PartnerRegistration, PartnerStatistic, PartnerEmployees, PersonCompleted, Requisites } from '../pages';
import { Specialist, NewClient, ClientInfo, Staff, ContractSelect, NewContract, LoanAgreement, Contracts, AddContract, Statistic, StatisticDetails, Income, Request, Information } from '../pagesPartners'
import NewClientCompleted from '../pagesPartners/NewClientCompleted/NewClientCompleted'
import Spinner from '../Spinner/Spinner';
import Authorization from '../Authorization/Authorization';
import { AuthSessionAPI } from '../../api/AuthSessionAPI';
import { GetUserInfo } from '../../store/Auth/types';
import SnackbarAlert from '../bricks/SnackbarAlert';
import Employee from '../pages/Employee/Employee';
import AddEmployee from '../pages/AddEmployee/AddEmployee';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const AppStack:React.FC<Props> = (props) => {
    const { sessionId, isAuthorized, loading, getUserInfoAction, setLoaderAction } = props;

    const [notification, setNotification] = useState(false);
    const [errorMessage, setErrorMessage] = useState({
        error: false,
        message: '',
    });

    const catchError = (message: string) => {
        setNotification(true);
        setErrorMessage({error: true, message});
    }

    useEffect(() => {
        if(sessionId) {
            setLoaderAction(true);
            AuthSessionAPI.auth(sessionId).then(data => {
                getUserInfoAction(data, true);
                setLoaderAction(false);
            }).catch(({response}) => catchError(response.data.message));
        }
    }, [sessionId, getUserInfoAction, setLoaderAction]);

    if(loading) {
        return <Spinner fixed="fixed" />
    }

    if(!isAuthorized) {
        return <Authorization />
    }

    return (
        <>
            <Switch>
                <Route 
                    path="/" 
                    component={Home} 
                    exact />
                <Route 
                    path="/partners" 
                    component={Partners} 
                    exact />
                <Route 
                    path="/partners/:creditOrganizationId" 
                    component={PartnerEmployees} 
                    exact />
                <Route 
                    path="/partners/:creditOrganizationId/:customerId" 
                    component={Employee} />
                <Route 
                    path="/statistics" 
                    component={Statistics} 
                    exact />
                <Route 
                    path="/statistics/:creditOrganizationId" 
                    component={Organizations} 
                    exact />
                <Route 
                    path="/statistics/:creditOrganizationId/:customerId" 
                    component={Person} />
                <Route 
                    path="/customers" 
                    component={Сustomers} />
                <Route 
                    path="/organizations" 
                    component={Organizations} />
                <Route 
                    path="/person" 
                    component={Person} />
                <Route 
                    path="/partnerRegistration" 
                    component={PartnerRegistration} />
                <Route 
                    path="/partnerStatistic" 
                    component={PartnerStatistic} />
                <Route 
                    path="/partnerEmployees" 
                    component={PartnerEmployees} />
                <Route 
                    path="/newEmployee" 
                    component={AddEmployee} />
                <Route 
                    path="/personCompleted" 
                    component={PersonCompleted} />
                <Route 
                    path="/requisites/:organizationId" 
                    component={Requisites} />
                <Route 
                    path="/editRequisites/:organizationId" 
                    component={PartnerRegistration} />
            </Switch>
            <Switch>
                <Route 
                    path="/specialist" 
                    component={Specialist} />
                <Route 
                    path="/newClient" 
                    component={NewClient} />
                <Route 
                    path="/newClientCompleted" 
                    component={NewClientCompleted} /> 
                <Route 
                    path="/clientInfo" 
                    component={ClientInfo} />
                <Route 
                    path="/staff" 
                    component={Staff} />
                <Route 
                    path="/contractSelect" 
                    component={ContractSelect} /> 
                <Route 
                    path="/newContract" 
                    component={NewContract} /> 
                <Route 
                    path="/loanAgreement" 
                    component={LoanAgreement} />
                <Route 
                    path="/contracts" 
                    component={Contracts} />  
                <Route 
                    path="/addContract" 
                    component={AddContract} /> 
                <Route 
                    path="/statisticPartner" 
                    component={Statistic} /> 
                <Route 
                    path="/statisticDetails" 
                    component={StatisticDetails} /> 
                <Route 
                    path="/income" 
                    component={Income} />
                <Route 
                    path="/request" 
                    component={Request} />
                <Route 
                    path="/information" 
                    component={Information} />
            </Switch>
            <SnackbarAlert 
                notification={notification}
                setNotification={setNotification} 
                message={errorMessage.message}
                severity={errorMessage.error ? "error" : "success"}
                vertical="top" 
                horizontal="center" />
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    sessionId: state.auth.sessionId,
    isAuthorized: state.auth.isAuthorized,
    loading: state.auth.loading,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    getUserInfoAction: (getUserInfo: GetUserInfo, isAuthorized: boolean) => dispatch(actions.auth.getUserInfoAction(getUserInfo, isAuthorized)),
    setLoaderAction: (loading: boolean) => dispatch(actions.auth.setLoaderAction(loading)),
    logoutAction: () => dispatch(actions.auth.logoutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppStack);