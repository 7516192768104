import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Box, Button } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useHistory, NavLink } from 'react-router-dom'
import Paginations from '../../Paginatins/Paginations'


const ContractSelect = () => {
    const classes = useStyles()
    let history = useHistory()

    return (
        <Grid item xs className="contentGrid">
            <Box mb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Button 
                            variant="contained" 
                            color="inherit" 
                            size="large" 
                            disableElevation
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.goBack()}>
                            Назад
                        </Button>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="h4" className={classes.h4}>Абдышева Асия Нурлановна</Typography>
                        <Typography variant="h5" className={classes.h5}>ИНН: 13011198400286</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={2}></Grid>
                <Grid item xs={9}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <NavLink to="newContract" className={classes.contractBox}>
                                <strong className={classes.title}>МИНИ</strong>
                                <div className={classes.mb2}><strong>Потребительский</strong></div>
                                <div className={classes.mb2}>Ставка - <strong>16,5%</strong></div>
                                <div>Срок - <strong>3-12 месяцев</strong></div>
                            </NavLink>
                        </Grid>
                        <Grid item xs={3}>
                            <NavLink to="newContract" className={classes.contractBox}>
                                <strong className={classes.title}>МАКСИ</strong>
                                <div className={classes.mb2}><strong>Потребительский</strong></div>
                                <div className={classes.mb2}>Ставка - <strong>12%</strong></div>
                                <div>Срок - <strong>12 месяцев</strong></div>
                            </NavLink>
                        </Grid>
                        <Grid item xs={3}>
                            <NavLink to="newContract" className={classes.contractBox}>
                                <strong className={classes.title}>ТЕХНОДОМ</strong>
                                <div className={classes.mb2}><strong>Потребительский</strong></div>
                                <div className={classes.mb2}>Ставка - <strong>16,5%</strong></div>
                                <div>Срок - <strong>3-12 месяцев</strong></div>
                            </NavLink>
                        </Grid>
                        <Grid item xs={3}>
                            <NavLink to="newContract" className={classes.contractBox}>
                                <strong className={classes.title}>ПЕНСИОННЫЙ</strong>
                                <div className={classes.mb2}><strong>Потребительский</strong></div>
                                <div className={classes.mb2}>Ставка - <strong>10,5%</strong></div>
                                <div>Срок - <strong>3-12 месяцев</strong></div>
                            </NavLink>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} className={classes.center}>
                            <Box mt={45}>
                                <Paginations count={10} />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ContractSelect

const useStyles = makeStyles(theme => ({
    root: {
        color: '#000000',
        marginBottom: 25,
        '& .MuiInputBase-root': {
          borderRadius: 8
        }
    },
    h4: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
        fontSize: 32,
        color: '#000000',
        marginBottom: 10
    },
    h5: {
        fontWeight: 600,
        fontSize: 20,
        color: '#000000'
    }, 
    center: {
        textAlign: 'center'
    },
    right: {
        textAlign: 'right'
    },
    contractBox: {
        boxShadow: '0px 2px 30px rgba(0, 0, 0, 0.15)',
        borderRadius: 8,
        opacity: 0.8,
        padding: 24,
        fontSize: 14,
        display: 'block',
        color: '#333333',
        transition: 'all .3s ease',
        '&:hover': {
            background: '#2AA3EF',
            color: '#fff',
            boxShadow: 'none'
        }
    },
    mb2: {
        marginBottom: 10
    },
    title: {
        textTransform: 'uppercase',
        display: 'block',
        marginBottom: 3
    }
}))