import axios from 'axios';
import { creditBureauUrl } from '../utils/baseUrl';

const instance = axios.create({
    baseURL: `${creditBureauUrl()}api/Customers/`,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "apiKey": "DE57A23D-D449-4279-A2FB-14A3C5F650F2",
    }
})

export const CustomersAPI = {
    async getPassportInfo(sessionId: string, customerId: number) {
        return await instance.get(`GetPassportInfo/${sessionId}/${customerId}`).then(res => {
            return res.data;
        });
    },
    async getUserReports(sessionId: string, customerId: number) {
        return await instance.get(`GetUserReports/${sessionId}/${customerId}`).then(res => {
            return res.data;
        });
    },
    async getCertificate(sessionId: string, customerId: number) {
        return await instance.get(`GetCertificate/${sessionId}/${customerId}`).then(res => {
            return res.data;
        });
    },
};

export interface IGetCertificate {
    certifyingCenter: string,
    algorithmIdentifier: string,
    dateRegistration: Date,
    keyValue: string,
}
export interface PassportInfo {
    userName: string,
    name: string,
    surname: string,
    patronymic: string,
    passportNumber: string,
    userInn: string,
    dateBirth: string,
    dateExpiry: string,
    dateIssue: string,
    authority: string,
    passportFrontBase64: string,
    passportBackBase64: string,
    userPhotoBase64: string,
}

export interface IUserReports {
    customerId: number,
    customerName: string,
    ishenimReport: {
        requeststring: string,
        mainScoring: {
        date: Date,
        grade: string,
        probabilityOfDefault: number,
        score: number,
        trend: string,
        riskColor: string,
        explanation: string
        },
        scoringItems: IScoringItems[],
        contracts: IContracts[],
        fullName: string,
        dateOfBirth: string,
        internalPassport: string,
        socCode: string,
        mainAddress: string,
        secondaryAddress: string,
        requestId: number,
        requestDate: Date,
        reportstring: string
    },
    settlementsServiceReport: {
        workPeriods: IWorkPeriods[],
        requestId: number,
        requestDate: Date,
        reportstring: string
    }
}

export interface IScoringItems {
    date: Date,
    grade: string,
    probabilityOfDefault: number,
    score: number,
    trend: string,
    riskColor: string,
    explanation: string
}

export interface IContracts {
    totalTakenAmount: string,
    numberOfDueInstallments: number,
    contractstring: string,
    contractstringText: string,
    iconId: number,
    stringColor: string,
    contractGroupstringText: string,
    pastDueDays: number,
    date: string,
    contractItems: IContractItems[]
}

export interface IContractItems {
    label: string,
    value: string
}

export interface IWorkPeriods {
    piN_LSS: string,
    payer: string,
    inn: string,
    numSF: string,
    dateBegin: string,
    dateEnd: string,
    salary: number
}
