import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, Typography, Button } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import Paginations from '../../Paginatins/Paginations'
import PartnerStaffTable from '../../bricks/PartnerStaffTable'
import MenuDrawer from '../../MenuDrawer/MenuDrawer'

const Staff = () => {
    const classes = useStyles()

    return (
        <>
            <MenuDrawer />
            <Grid item xs className="contentGrid">
                <Box mb={3}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={7}>
                            <Typography variant="h4" className={classes.h4}>Сотрудники</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography align="right">
                                <NavLink to="/newEmployee">
                                    <Button variant="contained" color="primary" size="large" disableElevation>
                                        Новый сотрудник
                                    </Button>
                                </NavLink>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.mb5}>
                        <PartnerStaffTable />
                    </Grid>
                    <Grid item xs={12} className={classes.center}>
                        <Paginations count={10} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Staff

const useStyles = makeStyles(theme => ({
    h4: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
        fontSize: 32
    },
    h5: {
        fontWeight: 600,
        fontSize: 20
    },
    center: {
        textAlign: 'center'
    },
    mb5: {
        marginBottom: 25
    }
}))