import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Grid, Box, TextField } from '@material-ui/core'
// import personImg from '../../images/person.jpg'
import './PersonDetail.scss'
import { PersonalIcon } from '../../Icons/Icons'
import { PassportInfo, IGetCertificate } from '../../api/CustomersAPI';
import Moment from 'react-moment';

type Props = {
    passportInfo: PassportInfo;
    certificate: IGetCertificate | null
}

const PersonDetail:React.FC<Props> = ({ passportInfo, certificate }) => {
  const classes = useStyles()

  return (
    <Grid container>
        <Grid item xs={2}></Grid>
        <Grid item xs={9}>
            <div className="personCard">
                <div className="personCard__item userPhoto">
                    <img src={`data:image/jpeg;base64,${passportInfo.userPhotoBase64}`} alt={passportInfo.name} />
                    {window.location.pathname === '/personCompleted' &&
                        <Box className="personalNumberBox">
                            <TextField 
                                label="Номер телефона" 
                                variant="outlined" 
                                fullWidth 
                                value="996 555 12 02 19" />
                        </Box>
                    }
                </div>
                <div className="personCard__item">
                    <div className="personBox">
                        <div className="personBox__item">
                            <Box className={classes.box}>
                                <Typography className={classes.strong}>Персональный номер</Typography>
                                <Typography>{passportInfo.userInn}</Typography>
                            </Box>
                            <Box className={classes.box}>
                                <Typography className={classes.strong}>Паспорт серия №</Typography>
                                <Typography>{passportInfo.passportNumber}</Typography>
                            </Box>
                            <Box className={classes.box}>
                                <Typography className={classes.strong}>Дата рождения</Typography>
                                <Typography>{passportInfo.dateBirth}</Typography>
                            </Box>
                        </div>
                        <div className="personBox__item">
                            <Box className={classes.box}>
                                <Typography className={classes.strong}>Дата выдачи паспорта</Typography>
                                <Typography>{passportInfo.dateIssue}</Typography>
                            </Box>
                            <Box className={classes.box}>
                                <Typography className={classes.strong}>Орган, выдавший паспорт</Typography>
                                <Typography>{passportInfo.authority}</Typography>
                            </Box>
                            <Box className={classes.box}>
                                <Typography className={classes.strong}>Срок окончания действия паспорта</Typography>
                                <Typography>{passportInfo.dateExpiry}</Typography>
                            </Box>
                        </div>
                        {certificate && 
                            <div className="personBox__fullWidth">
                                <Box className={classes.box}>
                                    <Typography className={classes.strong}>Данные ЭЦП</Typography>
                                    <Typography>Удостоверяющий центр: {certificate.certifyingCenter}</Typography>
                                    <Typography>Идентификатор алгоритма ЭЦП: {certificate.algorithmIdentifier}</Typography>
                                    <Typography>Дата и время регистрации: <Moment format="DD.MM.YYYY, HH:mm:ss">{certificate.dateRegistration}</Moment></Typography>
                                    <Typography>Тип ключа: Открытый ключ</Typography>
                                    <Typography>Длина ключа: 512 Бит</Typography>
                                    <Typography style={{wordWrap: 'break-word'}}>{certificate.keyValue}</Typography>
                                </Box>
                            </div>
                        }
                        <div className="personBox__item">
                            <Box mt={1}>
                                <img 
                                    src={`data:image/jpeg;base64,${passportInfo.passportFrontBase64}`} alt="password" 
                                    className="passwordImg" />
                            </Box>
                        </div>
                        <div className="personBox__item">
                            <Box mt={1}>
                                <img 
                                    src={`data:image/jpeg;base64,${passportInfo.passportBackBase64}`} alt="password" 
                                    className="passwordImg" />
                            </Box>
                        </div>
                    </div>
                    {window.location.pathname === '/person' &&
                        <div className="personalData">
                            <div className="personalData__svg"><PersonalIcon /></div>
                            <Typography className="personalData__typography">Разрешение на обработку <br /> и передачу персональных данных</Typography>
                        </div>
                    }
                    {window.location.pathname === '/personCompleted' &&
                        <Box className="personalNumberBox" mt={2}>
                            <strong className="pr1">Должность:</strong>
                            <span>Администратор</span>
                        </Box>
                    }
                </div>
            </div>
        </Grid>
    </Grid>
  )
}

export default PersonDetail

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    strong: {
        fontWeight: 700
    },
    box: {
        marginBottom: 12,
        width: '100%',
    },
    married: {
        color: '#FA7800',
        fontWeight: 'bold'
    }
  })
)