import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
  
  function createData(month: string, organzation: string, salary: string) {
    return { month, organzation, salary };
  }
  
  const income = [
    createData('Январь 2020', 'ОсОО Оной', '25 000 с'),
    createData('Декабрь 2020', 'ОсОО Оной', '25 000 с'),
    createData('Ноябрь 2020', 'ОсОО Оной', '25 000 с'),
    createData('Октябрь 2020', 'ОсОО Оной', '25 000 с'),
    createData('Сентябрь 2020', 'ОсОО Оной', '25 000 с'),
    createData('Август 2020', 'ОсОО Оной', '25 000 с'),
    createData('Июль 2020', 'ОсОО Оной', '25 000 с'),
    createData('Июнь 2020', 'ОсОО Оной', '25 000 с'),
    createData('Май 2020', 'ОсОО Оной', '25 000 с'),
    createData('Апрель 2020', 'ОсОО Оной', '25 000 с'),
  ];

const IncomeTable = () => {

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell align="left">№</TableCell>
                    <TableCell>Месяц</TableCell>
                    <TableCell align="left">Организация</TableCell>
                    <TableCell align="right">Заработная плата</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {income.map((income, index) => (
                    <TableRow key={index}>
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell component="th" scope="row">{income.month}</TableCell>
                        <TableCell align="left">{income.organzation}</TableCell>
                        <TableCell align="right">{income.salary}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default IncomeTable