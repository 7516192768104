import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import { IOrganizations } from '../../api/AdminAPI';
import { useHistory } from 'react-router-dom';
import { sessionStorageSetItem } from '../../utils/storage';

type Props = {
    organizations: IOrganizations[]
}

const PartnersTable:React.FC<Props> = ({ organizations }) => {
    let history = useHistory();

    const organizationClick = (organization: IOrganizations) => {
        sessionStorageSetItem("organizationName", organization.organizationName);
        return history.push(`/partners/${organization.creditOrganizationId}`);
    }

    const profileClick = (creditOrganizationId: number) => {
        return history.push(`/requisites/${creditOrganizationId}`);
    }

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell align="left">№</TableCell>
                    <TableCell>Организация</TableCell>
                    <TableCell align="center">Сотрудники</TableCell>
                    <TableCell align="center">Профиль</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {organizations.map((organization, index) => (
                    <TableRow key={index}>
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell component="th" scope="row">{organization.organizationName}</TableCell>
                        <TableCell align="center">
                            <Button className="tableLinkBlue" onClick={() => organizationClick(organization)}>Подробнее</Button>
                        </TableCell>
                        <TableCell align="center">
                            <Button className="tableLinkBlue" onClick={() => profileClick(organization.creditOrganizationId)}>Подробнее</Button>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default PartnersTable