import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 201
const defaultColor = '#495974'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		background: '#233148',
        height: '100%',
        position: 'relative'
	},
	drawerPaper: {
		position: 'static',
		background: 'transparent',
		border: 'none',
		paddingLeft: 10,
		paddingRight: 10,
		boxSizing: 'border-box',
		'& a': {
			color: '#A7ADB6',
            textDecoration: 'none',
			'&.active': {
				'& .MuiListItem-root': {
					backgroundColor: defaultColor,
                    color: '#fff',
					'& svg path': {
						fill: '#fff'
					}
				}
			}
		},
		'& .MuiList-padding': {
			paddingTop: 24
		}
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	listItem: {
		padding: '6px 21px',
		transition: 'all .3s ease',
		marginTop: 3,
        marginBottom: 3,
        borderRadius: 2,
		'& svg path': {
            transition: 'all .3s ease',
            fill: '#A7ADB6'
		},
		'&:hover': {
			backgroundColor: defaultColor,
			color: '#fff',
			'& svg path': {
				fill: '#fff'
			}
		},
		'& .MuiTypography-root': {
			fontSize: 16,
			fontWeight: 400
		}
	},
	listItemIcon: {
		minWidth: 'auto',
		marginRight: 29
	},
	profile: {
		position: 'absolute',
		bottom: '1rem',
		maxWidth: 180,
        width: '100%',
        transition: 'all .3s ease',
        '& .MuiTypography-root': {
            color: '#A7ADB6',
        },
        '&:hover': {
            '& .MuiTypography-root': {
                color: '#fff'
            },
        }
	},
	icon: {
		color: '#333333'
	}
}))

export { useStyles }