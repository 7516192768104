import React from 'react'
import { Grid, Typography, Box, Button } from '@material-ui/core'
import { useStyles } from './IncomeStyle'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useHistory } from 'react-router-dom'
import IncomeTable from '../../bricks/IncomeTable'


const Income = () => {
    const classes = useStyles()
    let history = useHistory()

    return (
        <Grid item xs className="contentGrid">
            <Box mb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Button 
                            variant="contained" 
                            color="inherit" 
                            size="large" 
                            disableElevation
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.goBack()}>
                            Назад
                        </Button>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="h4" className={classes.h4}>Абдышева Асия Нурлановна</Typography>
                        <Typography variant="h5" className={classes.h5}>Дата запроса: 20 февраля 2020</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={2}></Grid>
                <Grid item xs={7}>
                    <IncomeTable />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Income