import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { NavLink } from 'react-router-dom';
  
  function createData(name: string, history: string, income: string, contract: string) {
    return { name, history, income, contract };
  }
  
  const clients = [
    createData('Абдышев Азамат Нурланович', 'да', 'нет', 'Заключен'),
    createData('Абдышев Азамат Нурланович', 'да', 'нет', 'Заключен'),
    createData('Абдышев Азамат Нурланович', 'да', 'нет', 'Заключен'),
    createData('Абдышев Азамат Нурланович', 'да', 'нет', 'Заключен'),
    createData('Абдышев Азамат Нурланович', 'да', 'нет', 'Заключен')
  ];

const StatisticDetailsTable = () => {

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell align="left">№</TableCell>
                    <TableCell>Клиент</TableCell>
                    <TableCell align="center">Запрос кредитной истории</TableCell>
                    <TableCell align="center">Запрос по доходам</TableCell>
                    <TableCell align="center">Статус договора</TableCell>
                    <TableCell align="center">Действие</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {clients.map((person, index) => (
                    <TableRow key={index}>
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell component="th" scope="row">{person.name}</TableCell>
                        <TableCell align="center">{person.history}</TableCell>
                        <TableCell align="center">{person.income}</TableCell>
                        <TableCell align="center">{person.contract}</TableCell>
                        <TableCell align="center">
                            <NavLink to="/clientInfo">Подробнее</NavLink>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default StatisticDetailsTable