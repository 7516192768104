import React from 'react'
import { Grid, Typography, Box, Button, Tabs, Tab } from '@material-ui/core'
import { useStyles } from './InformationStyle'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { withRouter, useHistory, NavLink } from 'react-router-dom'
import ClientDetail from '../../ClientDetail/ClientDetail'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import SalaryTable from '../../bricks/SalaryTable'
import InformationTable from '../../bricks/InformationTable'


const Information = () => {
    const classes = useStyles()
    let history = useHistory()
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue)
    };

    return (
        <Grid item xs className="contentGrid">
            <Box mb={2}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                        <Button 
                            variant="contained" 
                            color="inherit" 
                            size="large" 
                            disableElevation
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.goBack()}>
                            Назад
                        </Button>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="h4" className={classes.h4}>Абдышева Асия Нурлановна</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                    <Tabs value={value} onChange={handleChange} className={classes.tabs}>
                        <Tab label="Информация" {...a11yProps(0)} />
                        <Tab label="Ишеним" {...a11yProps(1)} />
                        <Tab label="Заработная плата" {...a11yProps(2)} />
                        <Tab label="Патент" {...a11yProps(3)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <ClientDetail />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        1
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <SalaryTable />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        3
                    </TabPanel>
                    <Box className={classes.documentTableBox}>
                        <Box mb={3}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <Typography variant="h4" className={classes.h5}>Заявки</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <InformationTable />
                        <Grid item xs={12}>
                            <Box mt={4} mb={3}>
                                <Typography variant="h4" className={classes.h5}>Тип кредитного договора</Typography>
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <NavLink to="newContract" className={`${classes.contractBox} active`}>
                                        <strong className={classes.title}>МИНИ</strong>
                                        <div className={classes.mb2}><strong>Потребительский</strong></div>
                                        <div className={classes.mb2}>Ставка - <strong>16,5%</strong></div>
                                        <div>Срок - <strong>3-12 месяцев</strong></div>
                                        <CheckCircleOutlineIcon />
                                    </NavLink>
                                </Grid>
                                <Grid item xs={3}>
                                    <NavLink to="newContract" className={classes.contractBox}>
                                        <strong className={classes.title}>МАКСИ</strong>
                                        <div className={classes.mb2}><strong>Потребительский</strong></div>
                                        <div className={classes.mb2}>Ставка - <strong>12%</strong></div>
                                        <div>Срок - <strong>12 месяцев</strong></div>
                                        <CheckCircleOutlineIcon />
                                    </NavLink>
                                </Grid>
                                <Grid item xs={3}>
                                    <NavLink to="newContract" className={classes.contractBox}>
                                        <strong className={classes.title}>ТЕХНОДОМ</strong>
                                        <div className={classes.mb2}><strong>Потребительский</strong></div>
                                        <div className={classes.mb2}>Ставка - <strong>16,5%</strong></div>
                                        <div>Срок - <strong>3-12 месяцев</strong></div>
                                        <CheckCircleOutlineIcon />
                                    </NavLink>
                                </Grid>
                                <Grid item xs={3}>
                                    <NavLink to="newContract" className={classes.contractBox}>
                                        <strong className={classes.title}>ПЕНСИОННЫЙ</strong>
                                        <div className={classes.mb2}><strong>Потребительский</strong></div>
                                        <div className={classes.mb2}>Ставка - <strong>10,5%</strong></div>
                                        <div>Срок - <strong>3-12 месяцев</strong></div>
                                        <CheckCircleOutlineIcon />
                                    </NavLink>
                                </Grid>
                                <Grid item xs={12}>
                                    <NavLink to="/addContract">
                                        <Button variant="contained" color="primary" size="large" disableElevation className={classes.centerButton}>
                                            Отправить
                                        </Button>
                                    </NavLink>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withRouter(Information)

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
  
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            >
            {value === index && <Box p={3}>{children}</Box>}
            </Typography>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}