import axios from 'axios';
import { creditBureauUrl } from '../utils/baseUrl';

const instance = axios.create({
    baseURL: `${creditBureauUrl()}api/Pdf/`,
    responseType: 'blob', // important
    headers: {
        "Access-Control-Allow-Origin": "*",
        "apiKey": "DE57A23D-D449-4279-A2FB-14A3C5F650F2",
    }
})

export const PdfAPI = {
    async getIshenimReportPdf(sessionId: string, customerId: number) {
        return await instance.get(`GetIshenimReportPdf?sessionId=${sessionId}&customerId=${customerId}`).then(res => {
            return res.data;
        });
    },
    async getSettlementsServiceReportPdf(sessionId: string, customerId: number) {
        return await instance.get(`GetSettlementsServiceReportPdf?sessionId=${sessionId}&customerId=${customerId}`).then(res => {
            return res.data;
        });
    },
}