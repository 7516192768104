import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Box, Button, FormControl, InputLabel, Select, MenuItem, TextField, FormControlLabel, Checkbox, Divider } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { NavLink, useHistory } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'


const NewEmployee = () => {
    const classes = useStyles()
    let history = useHistory()
    const [position, setPosition] = React.useState('Администратор')
    const [checkedState, setCheckedState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedC: true,
        checkedD: true
    })

    const inputLabel = React.useRef<HTMLLabelElement>(null)
    const [labelWidth, setLabelWidth] = React.useState(0)

    React.useEffect(() => {
        setLabelWidth(inputLabel.current!.offsetWidth)
    }, [])

    const positionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPosition(event.target.value as string)
    }

    const checkedStateChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedState({ ...checkedState, [name]: event.target.checked })
    }

    return (
        <Grid item xs className="contentGrid">
            <Box mb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Button 
                            variant="contained" 
                            color="inherit" 
                            size="large" 
                            disableElevation
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.goBack()}>
                            Назад
                        </Button>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography variant="h4" className={classes.h4}>Новый сотрудник</Typography>
                        <Typography variant="h5" className={classes.h5}>ОсОО “Мол Булак”</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.right}>
                        <NavLink to="/partnerEmployees">
                            <CloseIcon className={classes.close} />
                        </NavLink>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={2}></Grid>
                <Grid item xs={9}>
                    <form className={classes.root} noValidate autoComplete="off">
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3}>
                                <FormControl variant="outlined" fullWidth className={classes.maxWidth}>
                                    <InputLabel ref={inputLabel}>
                                        Должность
                                    </InputLabel>
                                    <Select
                                        value={position}
                                        onChange={positionChange}
                                        labelWidth={labelWidth}>
                                        <MenuItem value='Администратор'>Администратор</MenuItem>
                                        <MenuItem value='Менеджер'>Менеджер</MenuItem>
                                        <MenuItem value='Директор'>Директор</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControlLabel
                                    className={classes.formControlLabel}
                                    control={
                                        <Checkbox
                                            checked={checkedState.checkedB}
                                            onChange={checkedStateChange('checkedB')}
                                            value="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <span>Дать доступ к самостоятельному заключению договоров</span>
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Box className={classes.boxShadow} mt={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField 
                                        label="Фамилия" 
                                        variant="outlined" 
                                        fullWidth 
                                        value="Абдышева" />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField 
                                        label="Имя" 
                                        variant="outlined" 
                                        fullWidth 
                                        value="Асия" />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField 
                                        label="Отчество" 
                                        variant="outlined" 
                                        fullWidth 
                                        value="Нурлановна" />
                                </Grid>
                                <Grid item xs={3}>
                                    <Box className={classes.imgDownload}>
                                        <Typography>Нажмите, чтобы добавить фото клиента</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <TextField 
                                                label="Персональный номер" 
                                                variant="outlined" 
                                                fullWidth 
                                                value="23011198400286" />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField 
                                                label="Номер документа" 
                                                variant="outlined" 
                                                fullWidth 
                                                value="AN 189 9867" />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField 
                                                label="Дата выдачи" 
                                                variant="outlined" 
                                                fullWidth 
                                                value="20.08.2016" />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField 
                                                label="Орган выдачи" 
                                                variant="outlined" 
                                                fullWidth 
                                                value="ИММ 50-05" />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField 
                                                label="Срок действия до" 
                                                variant="outlined" 
                                                fullWidth 
                                                value="20.08.2026" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField 
                                                label="Адрес проживания" 
                                                variant="outlined" 
                                                fullWidth 
                                                value="Бишкек, ул Фрунзе 232" />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box className={classes.imgDownloadSmall}>
                                                <Typography>Нажмите, чтобы добавить лицевую сторону паспорта</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box className={classes.imgDownloadSmall}>
                                                <Typography>Нажмите, чтобы добавить оборотную сторону паспорта</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    className={classes.formControlLabel}
                                    control={
                                        <Checkbox
                                            checked={checkedState.checkedA}
                                            onChange={checkedStateChange('checkedA')}
                                            value="checkedA"
                                            color="primary"
                                        />
                                    }
                                    label="Получил от сотрудника разрешение на обработку и передачу персональных данных"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    className={classes.formControlLabel}
                                    control={
                                        <Checkbox
                                            checked={checkedState.checkedB}
                                            onChange={checkedStateChange('checkedB')}
                                            value="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <span>Получил согласие сотрудника о присоединении <br /> к <NavLink to="">регламенту ПУЦ</NavLink></span>
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    className={classes.formControlLabel}
                                    control={
                                        <Checkbox
                                            checked={checkedState.checkedC}
                                            onChange={checkedStateChange('checkedC')}
                                            value="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <span>Получил согласие сотрудника с <NavLink to="">договором оферты</NavLink></span>
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    className={classes.formControlLabel}
                                    control={
                                        <Checkbox
                                            checked={checkedState.checkedD}
                                            onChange={checkedStateChange('checkedD')}
                                            value="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <span>Ознакомил сотрудника со сведениями содержащимися в его <NavLink to="">квалифицированном сертификате</NavLink></span>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </form>
                    <Divider />
                    <Grid container spacing={2} justify="center" className={classes.confirmation}>
                        <Grid item xs={12}>
                            <Typography component="h5" className={classes.h5} align="center">
                                Подтверждение регистрации сотрудником
                            </Typography>
                        </Grid>
                        <Box className={classes.confirmationBox}>
                            <TextField 
                                label="Номер телефона" 
                                variant="outlined" 
                                fullWidth 
                                value="996 555 12 02 19" />
                            <NavLink to="/personCompleted">
                                <Button variant="contained" color="primary" size="large" disableElevation>
                                    Отправить СМС
                                </Button>
                            </NavLink>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default NewEmployee

const useStyles = makeStyles(theme => ({
    root: {
        color: '#000000',
        marginBottom: 25,
        '& .MuiInputBase-root': {
          borderRadius: 8
        }
    },
    confirmation: {
        marginTop: 30,
        '& .MuiInputBase-root': {
            color: '#000000',
            borderRadius: 8
        },
        '& .MuiButtonBase-root': {
            padding: '15px 30px'
        },
        '& .MuiTypography-root': {
            marginBottom: 15
        },
        '& .MuiFormControl-root': {
            maxWidth: 188,
            paddingRight: 20
        }
    },
    h4: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
        fontSize: 32,
        color: '#000000',
        marginBottom: 10
    },
    h5: {
        fontWeight: 600,
        fontSize: 20
    }, 
    center: {
        textAlign: 'center'
    },
    right: {
        textAlign: 'right'
    },
    buttonColor: {
        color: '#333333'
    },
    close: {
        fontSize: 40,
        color: '#FF0000',
        marginRight: 50
    },
    maxWidth: {
        maxWidth: 194
    },
    boxShadow: {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        marginTop: 28,
        padding: 28,
        marginRight: 50,
        marginBottom: 28
    },
    imgDownload: {
        width: 205,
        height: 225,
        backgroundColor: '#E4E4E4',
        borderRadius: 12,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
            opacity: 0.6,
            color: "#000000",
            maxWidth: 170
        }
    },
    imgDownloadSmall: {
        width: 200,
        height: 123,
        backgroundColor: '#E4E4E4',
        borderRadius: 12,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
            opacity: 0.6,
            color: "#000000",
            maxWidth: 170
        }
    },
    formControlLabel: {
        alignItems: 'start',
        '& .MuiButtonBase-root': {
            padding: '0 9px 0'
        },
        '& a': {
            textDecoration: 'underline',
            '&:hover': {
                textDecoration: 'none'
            }
        }
    },
    confirmationBox: {
        display: 'flex'
    }
}))