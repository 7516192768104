import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    h4: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
        fontSize: 32
    },
    h5: {
        fontWeight: 600,
        fontSize: 20
    }, 
    center: {
        textAlign: 'center'
    },
    right: {
        textAlign: 'right'
    },
    boxItem: {
        background: '#FFFFFF',
        boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.15)',
        borderRadius: 12,
        padding: '16px 24px',
        flex: '0 0 230px',
        boxSizing: 'border-box'
    },
    headItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8
    },
    tableTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        fontSize: 20,
        fontWeight: 600,
        marginRight: 10
    },
    link: {
        textDecoration: 'underline',
        paddingTop: 2,
        '&:hover': {
            textDecoration: 'none'
        }
    },
    list: {
        marginBottom: 12,
        '&:last-child': {
            marginBottom: 0
        }
    },
    mb5: {
        marginBottom: 25
    },
    mb4: {
        marginBottom: 20
    },
    mt6: {
        marginTop: 34
    },
    tabs: {
        minHeight: 30,
        '& .MuiTabs-flexContainer': {
            height: 30,
            display: 'flex',
            alignItems: 'center'
        },
        '& .MuiButtonBase-root': {
            opacity: 1,
            minWidth: 'auto',
            minHeight: 30,
            padding: '0 10px',
            marginLeft: 3,
            marginRight: 3,
            transition: 'all .3s ease',
            '& .MuiTab-wrapper': {
                textTransform: 'none',
                fontSize: 12,
                fontWeight: 600,
                opacity: 1,
                color: '#323641'
            },
            '&:hover': {
                background: '#E8E8E8',
                borderRadius: 4
            },
        },
        '& .Mui-selected': {
            background: '#C1E8B7',
            borderRadius: 4
        },
        '& .MuiTabs-indicator': {
            display: 'none'
        }
    },
    rowTabPanel: {
       '& .MuiBox-root': {
           padding: 0,
           display: 'flex',
           alignItems: 'center'
       }     
    },
    ul: {
        padding: 0,
        paddingLeft: 60,
        '& li': {
            padding: '6px 0',
            fontSize: 14,
            listStyle: 'none',
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            '&:before': {
                content: '""',
                width: 14,
                height: 14,
                border: '4px solid #BE53EB',
                borderRadius: '50%',
                boxSizing: 'border-box',
                display: 'block',
                position: 'absolute',
                left: -24,
                top: 9
            },
            '& strong': {
                paddingLeft: 32
            }
        },
    },
    history: {
        '&:before': {
            content: '""',
            border: '4px solid #E6F3FF !important',
        }
    },
    buttonColor: {
        color: '#333333'
    },
    boxCount: {
        fontSize: 100,
        fontWeight: 700,
        color: '#BDC3C7',
        lineHeight: 1.1,
        marginTop: '1.8rem',
        textAlign: 'center',
    },
    boxDate: {
        color: '#828282',
        fontSize: 24,
        textAlign: 'left',
        fontWeight: 600
    },
    span: {
        opacity: '0.6',
        fontWeight: 400
    },
    containerBox: {
        display: 'flex',
        alignItems: 'flex-start',
        marginLeft: -12,
        marginRight: -12
    },
    itemBox: {
        margin: 12,
        flexDirection: 'column',
        display: 'flex'
    }
}))

export { useStyles }