import React from 'react'
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Grid,
    Divider,
} from '@material-ui/core'
import {
    MainIcon,
    PartnerIcon,
    StatisticsIcon,
    ClientsIcon,
    StaffIcon,
    ContractsIcon,
    PartnerStatisticIcon,
    SquareIcon,
} from '../../Icons/Icons'
import { useStyles } from './MenuDrawerStyle'
import { NavLink, withRouter, Redirect } from 'react-router-dom'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

const MenuDrawer = () => {
    const classes = useStyles()
    const { pathname } = window.location
    const getUserInfo = useSelector(
        (state: RootState) => state.auth.getUserInfo
    )

    // if (pathname.match(/organizations/) || pathname.match(/person/) || pathname.match(/partnerStatistic/)
    // 	|| pathname.match(/partnerRegistration/) || pathname.match(/partnerEmployees/) || pathname.match(/newEmployee/)
    // 	|| pathname.match(/requisites/) || pathname.match(/newClient/) || pathname.match(/clientInfo/) || pathname.match(/contractSelect/)
    // 	|| pathname.match(/newContract/) || pathname.match(/loanAgreement/) || pathname.match(/addContract/) || pathname.match(/statisticDetails/)
    // 	|| pathname.match(/income/) || pathname.match(/information/)) {
    // 	return null
    // }
    const ishenim =
        pathname.match(/partners/) ||
        pathname === '/' ||
        pathname.match(/statistics/) ||
        pathname.match(/customers/)
    const partners =
        pathname.match(/specialist/) ||
        pathname.match(/staff/) ||
        pathname.match(/newContract/) ||
        pathname.match(/contracts/) ||
        pathname.match(/statisticPartner/) ||
        pathname.match(/request/)

    if (
        getUserInfo &&
        !getUserInfo.isIshenim &&
        !getUserInfo.canCreateEmployees &&
        !getUserInfo.canViewStatistics
    ) {
        return <Redirect to={`/statistics/${getUserInfo.organizationId}`} />
    }

    return (
        <Grid item>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{ paper: classes.drawerPaper }}
            >
                {(ishenim ||
                    getUserInfo?.canCreateEmployees ||
                    getUserInfo?.isIshenim) &&
                    MainMenu(classes)}
                {partners && partnersMenu(classes)}
                <List className={classes.profile}>
                    <Divider />
                    <ListItem button className={classes.listItem}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <AccountCircleIcon className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText primary="Профиль" />
                    </ListItem>
                </List>
            </Drawer>
        </Grid>
    )
}

const MainMenu = (
    classes: Record<
        | 'root'
        | 'content'
        | 'appBar'
        | 'drawer'
        | 'drawerPaper'
        | 'listItem'
        | 'listItemIcon',
        string
    >
) => {
    const getUserInfo = useSelector(
        (state: RootState) => state.auth.getUserInfo
    )
    return (
        <List>
            {getUserInfo && getUserInfo.isIshenim && (
                <NavLink to="/" exact>
                    <ListItem button className={classes.listItem}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <MainIcon />
                        </ListItemIcon>
                        <ListItemText primary="Главная" />
                    </ListItem>
                </NavLink>
            )}
            {((getUserInfo && getUserInfo.canCreateEmployees) || (getUserInfo && getUserInfo.isIshenim)) && (
                <NavLink to="/partners">
                    <ListItem button className={classes.listItem}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <PartnerIcon />
                        </ListItemIcon>
                        <ListItemText primary="Партнеры" />
                    </ListItem>
                </NavLink>
            )}
            <NavLink to="/statistics">
                <ListItem button className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <StatisticsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Статистика" />
                </ListItem>
            </NavLink>
            {/*{getUserInfo && getUserInfo.isIshenim && (*/}
            {/*    <NavLink to="/customers">*/}
            {/*        <ListItem button className={classes.listItem}>*/}
            {/*            <ListItemIcon className={classes.listItemIcon}>*/}
            {/*                <ClientsIcon />*/}
            {/*            </ListItemIcon>*/}
            {/*            <ListItemText primary="Клиенты" />*/}
            {/*        </ListItem>*/}
            {/*    </NavLink>*/}
            {/*)}*/}
        </List>
    )
}

const partnersMenu = (
    classes: Record<
        | 'root'
        | 'content'
        | 'appBar'
        | 'drawer'
        | 'drawerPaper'
        | 'listItem'
        | 'listItemIcon',
        string
    >
) => {
    return (
        <List>
            <NavLink to="/specialist">
                <ListItem button className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ClientsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Клиенты" />
                </ListItem>
            </NavLink>
            <NavLink to="/staff">
                <ListItem button className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <StaffIcon />
                    </ListItemIcon>
                    <ListItemText primary="Сотрудники" />
                </ListItem>
            </NavLink>
            <NavLink to="/request">
                <ListItem button className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <SquareIcon />
                    </ListItemIcon>
                    <ListItemText primary="Заявки" />
                </ListItem>
            </NavLink>
            <NavLink to="/contracts">
                <ListItem button className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ContractsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Договоры" />
                </ListItem>
            </NavLink>
            <NavLink to="/statisticPartner">
                <ListItem button className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <PartnerStatisticIcon />
                    </ListItemIcon>
                    <ListItemText primary="Статистика" />
                </ListItem>
            </NavLink>
        </List>
    )
}

export default withRouter(MenuDrawer)
