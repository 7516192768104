import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
  
  function createData(name: string, sum: string, history: string, date: string) {
    return { name, sum, history, date };
  }
  
  const request = [
    createData('Абдышева Асия Нурлановна', '15 000', 'Кредитная история', '21 октября 2019')
  ];

const InformationTable = () => {

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell align="left">№</TableCell>
                    <TableCell>Клиент</TableCell>
                    <TableCell align="center">Сумма</TableCell>
                    <TableCell align="center">Документ</TableCell>
                    <TableCell align="center">Дата</TableCell>
                    <TableCell align="center">Действие</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {request.map((person, index) => (
                    <TableRow key={index}>
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell component="th" scope="row">{person.name}</TableCell>
                        <TableCell align="center">{person.sum}</TableCell>
                        <TableCell align="center">{person.history}</TableCell>
                        <TableCell align="center">{person.date}</TableCell>
                        <TableCell align="center"></TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default InformationTable