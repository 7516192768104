import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
import { Grid, Typography, Box, Button, TextField, FormControlLabel, Checkbox } from '@material-ui/core'
import { useStyles } from './EmployeeStyle'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
// import PersonDetail from '../../PersonDetail/PersonDetail'
// import DocumentTable from '../../bricks/DocumentTable'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { AdminAPI } from '../../../api/AdminAPI';
import { IGetEmployee } from '../../../store/Admin/types';
import SnackbarAlert from '../../bricks/SnackbarAlert';

type Props = ReturnType<typeof mapStateToProps>;

const Person:React.FC<Props> = ({ sessionId }) => {
    const classes = useStyles();
    let history = useHistory();
    const match = useRouteMatch();
    //@ts-ignore
    const customerId = parseInt(match.params.customerId);
    const [employee, setEmployee] = useState<IGetEmployee>({
        creditOrganizationId: 0,
        employeeId: customerId,
        employeeName: '',
        employeeInn: '',
        pin: '',
        phone: '',
        canViewStatistics: false,
        canCreateEmployees: false,
    });
    const [notification, setNotification] = useState(false);
    const [errorMessage, setErrorMessage] = useState({
        error: false,
        message: '',
    });

    const checkedStateChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmployee({ ...employee, [name]: event.target.checked })
    }

    const catchError = (message: string) => {
        setNotification(true);
        setErrorMessage({error: true, message});
    }

    useEffect(() => {
        if(sessionId) {
            AdminAPI.getEmployee(sessionId, customerId).then(data => {
                setEmployee({
                    creditOrganizationId: data.creditOrganizationId,
                    employeeId: data.employeeId,
                    employeeName: data.employeeName,
                    employeeInn: data.employeeInn,
                    pin: "****",
                    phone: data.phone,
                    canViewStatistics: data.canViewStatistics,
                    canCreateEmployees: data.canCreateEmployees,
                });
            }).catch(({response}) => console.log(JSON.stringify(response, null, 2)));
        }
    }, [sessionId, customerId]);

    const saveEmploee = () => {
        if(sessionId) {
            AdminAPI.setEmployee(sessionId, employee).then(data => {
                if(data.success === true) {
                    setNotification(true);
                    setErrorMessage({error: false, message: "Вы успешно отредактировали сотрудника!"});
                }
            }).catch(({response}) => catchError(response.data.message));
        }
    }
    
    return (
        <Grid item xs className="contentGrid">
            <Box mb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Button 
                            variant="contained" 
                            color="inherit" 
                            size="large" 
                            disableElevation
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.goBack()}>
                            Назад
                        </Button>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="h4" className={classes.h4}>{employee && employee.employeeName}</Typography>
                        <Typography><strong>ИНН: </strong>{employee && employee.employeeInn}</Typography>
                        <div className="edit-phone">
                            <TextField 
                                label="Телефон" 
                                variant="outlined"
                                value={employee.phone} 
                                onChange={(event) => setEmployee({...employee, phone: event.target.value})} 
                                size="small"
                            />
                        </div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={employee.canCreateEmployees}
                                    onChange={checkedStateChange('canCreateEmployees')}
                                    color="primary"
                                />
                            }
                            label="регистрирация пользователей"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={employee.canViewStatistics}
                                    onChange={checkedStateChange('canViewStatistics')}
                                    color="primary"
                                />
                            }
                            label="просмотр статистики"
                        />
                        <div className="textFieldRow">
                            <strong>Поменять ПИН: </strong>
                            <TextField 
                                label="ПИН" 
                                variant="outlined"
                                value={employee.pin} 
                                onChange={(event) => setEmployee({...employee, pin: event.target.value})} 
                                size="small" 
                                InputLabelProps={{ shrink: true }} />
                            <Button 
                                variant="contained" 
                                color="primary" 
                                size="large" 
                                disableElevation 
                                onClick={saveEmploee}>
                                Сохранить
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Box>
            {/* {passportInfo && 
                <PersonDetail passportInfo={passportInfo} />
            } */}
            {/* <Grid container spacing={2} justify="flex-end">
                <Grid item xs={10}>
                    <Typography variant="h5" className={classes.tableTitle}>Документы</Typography>
                    <DocumentTable />
                </Grid>
            </Grid> */}
            <SnackbarAlert 
                notification={notification}
                setNotification={setNotification} 
                message={errorMessage.message}
                severity={errorMessage.error ? "error" : "success"}
                vertical="top" 
                horizontal="center" />
        </Grid>
    )
}

const mapStateToProps = (state: RootState) => ({
    sessionId: state.auth.sessionId,
});

export default connect(mapStateToProps)(Person);