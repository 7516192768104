import axios from "axios";
import {creditBureauUrl} from "../utils/baseUrl";

const instance = axios.create({
    baseURL: `${creditBureauUrl()}api/Registration/`,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "apiKey": "DE57A23D-D449-4279-A2FB-14A3C5F650F2",
    }
});

const getDocument = axios.create({
    baseURL: `${creditBureauUrl()}api/Registration/`,
    responseType: 'blob',
    headers: {
        "Access-Control-Allow-Origin": "*",
        "apiKey": "DE57A23D-D449-4279-A2FB-14A3C5F650F2",
    }
});


export const PersonAPI = {
    async getUserDocuments() {
        return await instance.get('GetUserDocuments').then(res => {
            return res.data;
        });
    },
    async downloadUserDocument(sessionId: string, customerId: number, documentType: string) {
        return await getDocument.post(`GetUserDocument/${sessionId}/${customerId}/${documentType}`).then(res => {
            console.log(res)
            return res.data
        })
    }
};
