import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    colorTransparent: {
        boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.15)',
        background: '#0361CB',
        color: '#fff'
    },
    title: {
        flexGrow: 1,
        fontSize: 22,
        lineHeight: '150%',
        fontWeight: 600,
        // fontFamily: 'Lato, sans-serif',
        marginRight: 24
    },
    group: {
        marginRight: 46,
        fontSize: 13,
        fontWeight: 600,
        // fontFamily: 'Lato, sans-serif'
    },
    name: {
        paddingRight: 30
    },
    login: {
        fontSize: 13,
        textTransform: 'none',
        textDecoration: 'underline',
        position: 'relative',
        // fontFamily: 'Lato, sans-serif',
        marginLeft: 31,
        color: '#fff',
        '&::before': {
            content: '""',
            position: "absolute",
            display: 'block',
            width: 1,
            height: "100%",
            background: '#C4C4C4',
            left: -31
        }
    },
    gridContainer: {
        maxWidth: 1422,
        margin: 'auto',
        paddingLeft: 24,
        paddingRight: 18
    }
}))

export { useStyles }