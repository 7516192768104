const { pathname } = window.location

const headerTitle = () => {
    switch (pathname) {
        case '/partnerStatistic':
            return 'Партнеры'
        case '/organizations':
            return 'Статистика'
        case '/person':
            return 'Статистика'
        case '/partnerEmployees':
            return 'Сотрудники партнера'
        case '/newEmployee':
            return 'Регистрация сотрудника'
        case '/personCompleted':
            return 'Регистрация сотрудника'
        case '/requisites':
            return 'Реквизиты'
        case '/specialist':
            return 'Кабинет кредитного специалиста'
        case '/newClient':
            return 'Регистрация клиента'
        case '/newClientCompleted':
            return 'Регистрация клиента'
        case '/clientInfo':
            return 'Клиенты'
        case '/contractSelect':
            return 'Договоры'
        case '/newContract':
            return 'Договоры'
        case '/addContract':
            return 'Договоры'
        case '/statisticPartner':
            return 'Кабинет кредитного специалиста'
        case '/statisticDetails':
            return 'Статистика'
        case '/income':
            return 'Справка о доходах'
        case '/information':
            return 'Информация'
        default:
            return 'Административная панель'
    }
}

export { headerTitle }