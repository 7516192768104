import React, { Dispatch, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { RootState, actions } from '../../../store'
import { ThunkDispatch } from 'redux-thunk'
import { Action, AnyAction } from 'redux'
import { Grid, Typography, Box, Button } from '@material-ui/core'
import { useStyles } from './OrganizationsStyle'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import AllEmployees from '../../bricks/AllEmployees'
import Search from '../../bricks/Search'
import OrganizationsTable from '../../bricks/OrganizationsTable'
import { useHistory, useRouteMatch } from 'react-router-dom'
import Pagination from '@material-ui/lab/Pagination'
import { AdminAPI, ICustomers, ITotalRequests } from '../../../api/AdminAPI'
import DateRange from '../../bricks/DateRange'
// import { sessionStorageGetItem } from '../../../utils/storage'
import { ExcelApi } from '../../../api/ExcelApi'
import {sessionStorageGetItem} from "../../../utils/storage";

type Props = ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>

const Organizations: React.FC<Props> = ({
    sessionId,
    startDate,
    endDate,
    employeeId,
    search,
    searchAction,
    canViewStatistics,
    getUserInfo
}) => {
    const classes = useStyles()
    let history = useHistory()
    const match = useRouteMatch()
    //@ts-ignore
    const creditOrganizationId = parseInt(match.params.creditOrganizationId)
    const [customers, setCustomers] = useState<ICustomers[]>([])
    const [foundCustomers, setFoundCustomers] = useState(0)
    const [page, setPage] = React.useState(1)
    const limit = 10
    const min = (page - 1) * limit
    const [totalRequests, setTotalRequests] = useState<ITotalRequests | null>(
        null
    )
    const organizationName = sessionStorageGetItem('organizationName')

    useEffect(() => {
        if (sessionId) {
            if(getUserInfo && !getUserInfo.canViewStatistics) {
                AdminAPI.searchOrganizationCustomers(
                    sessionId,
                    limit,
                    min,
                    creditOrganizationId,
                    startDate,
                    endDate,
                    search,
                    getUserInfo.employeeId
                ).then((data) => {
                    setCustomers(data.customers)
                    setFoundCustomers(data.foundCustomers)
                })
            } else {
                AdminAPI.searchOrganizationCustomers(
                    sessionId,
                    limit,
                    min,
                    creditOrganizationId,
                    startDate,
                    endDate,
                    search,
                    employeeId
                ).then((data) => {
                    setCustomers(data.customers)
                    setFoundCustomers(data.foundCustomers)
                })
            }
        }
    }, [
        sessionId,
        page,
        min,
        creditOrganizationId,
        startDate,
        endDate,
        search,
        employeeId,
        getUserInfo
    ])

    useEffect(() => {
        if (sessionId) {
            AdminAPI.getRequestStatistics(
                sessionId,
                startDate,
                endDate,
                creditOrganizationId
            ).then((data) => {
                setTotalRequests(data)
            })
        }
    }, [sessionId, startDate, endDate, creditOrganizationId])

    const paginationChange = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setPage(value)
    }

    const downloadExelCustomers = () => {
        if (sessionId) {
            ExcelApi.getOrganizationsCustomers(
                sessionId,
                creditOrganizationId,
                startDate,
                endDate
            ).then((data) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `${organizationName}.xlsx`)
                document.body.appendChild(link)
                link.click()
            })
        }
    }

    let currentCount = (foundCustomers - (foundCustomers % limit)) / limit
    if (foundCustomers % limit > 0) {
        currentCount = currentCount + 1
    }

    const goBackClick = () => {
        searchAction(null)
        return history.goBack()
    };
    return (
        <Grid item xs className="contentGrid">
            <Box mb={3}>
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justify="space-between"
                >
                    <Grid item>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item>
                                <Button
                                        variant="contained"
                                        color="inherit"
                                        size="large"
                                        disableElevation
                                        startIcon={<ArrowBackIosIcon />}
                                        onClick={goBackClick}
                                    >
                                    Назад
                                </Button>
                            </Grid>
                            <Grid item>
                                <div className="title">{organizationName}</div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2} justify="flex-end">
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disableElevation
                                    onClick={downloadExelCustomers}
                                >
                                    Cкачать excel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Search />
                            </Grid>
                            <Grid item>
                                {(canViewStatistics || getUserInfo?.isIshenim) && (
                                    <AllEmployees
                                        creditOrganizationId={
                                            creditOrganizationId
                                        }
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                <DateRange />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <OrganizationsTable
                        customers={customers}
                        creditOrganizationId={creditOrganizationId}
                    />
                </Grid>
                {totalRequests && (
                    <Grid item xs={12}>
                        <Box mb={3}>
                            <Typography
                                variant="h5"
                                align="right"
                                className={classes.h5}
                            >
                                Итого запросов <span className="line">|</span>{' '}
                                Идентификация: {totalRequests.countRegistrations} <span className="line">|</span> {' '}
                                Ишеним: {totalRequests.ishenim}{' '}
                                <span className="line">|</span> Доходы:{' '}
                                {totalRequests.settlementsService}
                            </Typography>
                        </Box>
                    </Grid>
                )}
                <Grid item xs={12} className={classes.center}>
                    {foundCustomers > 10 && (
                        <Pagination
                            count={currentCount}
                            page={page}
                            onChange={paginationChange}
                            shape="rounded"
                            className={classes.root}
                        />
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state: RootState) => ({
    sessionId: state.auth.sessionId,
    startDate: state.admin.startDate,
    endDate: state.admin.endDate,
    employeeId: state.admin.employeeId,
    search: state.admin.search,
    canViewStatistics: state.auth.getUserInfo?.canViewStatistics,
    canCreateEmployees: state.auth.getUserInfo?.canCreateEmployees,
	organizationName: state.auth.getUserInfo?.organizationName,
	getUserInfo: state.auth.getUserInfo
})

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    searchAction: (search: string | null) =>
        dispatch(actions.admin.searchAction(search)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Organizations)
