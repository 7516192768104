import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    h4: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
        fontSize: 32,
        paddingBottom: 5
    },
    h5: {
        fontWeight: 600,
        fontSize: 20
    }, 
    center: {
        textAlign: 'center'
    },
    right: {
        textAlign: 'right'
    },
    tableTitle: {
        marginBottom: 30,
        fontSize: 24,
        fontWeight: 600,
        color: '#000000'
    },
    confirmation: {
        '& .MuiInputBase-root': {
            color: '#000000',
            borderRadius: 8
        },
        '& .MuiButtonBase-root': {
            padding: '15px 30px'
        },
        '& .MuiTypography-root': {
            marginBottom: 15
        },
        '& .MuiFormControl-root': {
            maxWidth: 188,
            paddingRight: 20
        }
    },
    confirmationBox: {
        display: 'flex'
    },
    confirmationDesc: {
        color: '#757575'
    },
    close: {
        fontSize: 40,
        color: '#FF0000'
    }
}))

export { useStyles }