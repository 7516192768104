import React from 'react'
import { Grid, Typography, Box, Button, Tabs, Tab } from '@material-ui/core'
import { useStyles } from './PartnerStatisticStyle'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { NavLink } from 'react-router-dom'
import { RoundChartIcons } from '../../../Icons/Icons'
// import StaffTable from '../../bricks/StaffTable'


const PartnerStatistic = () => {
    const classes = useStyles()
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue)
    };

    return (
        <Grid item xs className="contentGrid">
            <Box mb={3}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                        <NavLink to="/partners">
                            <Button 
                                variant="contained" 
                                color="inherit" 
                                size="large" 
                                disableElevation
                                startIcon={<ArrowBackIosIcon />}
                                className={classes.buttonColor}>
                                Партнеры
                            </Button>
                        </NavLink>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="h4" className={classes.h4}>Мол Булак</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Box className={classes.boxItem}>
                                <Box className={classes.headItem}>
                                    <Box className={classes.title}>Реквизиты</Box>
                                    <NavLink to="/requisites" className={classes.link}>Подробнее</NavLink>
                                </Box>
                                <Box className={classes.list}>
                                   <Box><strong>ИНН</strong></Box>
                                    <span>01203199900234</span>
                                </Box>
                                <Box className={classes.list}>
                                   <Box><strong>Адрес</strong></Box>
                                    <span>Бишкек, ул. Патриса Лумумбы 256</span>
                                </Box>
                                <Box className={classes.list}>
                                   <Box><strong>Банк</strong></Box>
                                    <span>ОАО Коммерческий Банк “ФинансКредитБанк - <br /> Филиал “Запад”</span>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box className={classes.boxItem}>
                                <Box className={`${classes.headItem} ${classes.mb5}`} justifyContent="space-between">
                                    <Box className={classes.title}>Статистика запросов</Box>
                                    <Tabs value={value} onChange={handleChange} className={classes.tabs}>
                                        <Tab label="Текущий месяц" {...a11yProps(0)} />
                                        <Tab label="Февраль" {...a11yProps(1)} />
                                        <Tab label="2020 год" {...a11yProps(2)} />
                                    </Tabs>
                                </Box>
                                <Box className={classes.rowTabPanel}>
                                    <TabPanel value={value} index={0}>
                                        <Box>
                                            <RoundChartIcons />
                                        </Box>
                                        <Box>
                                            <ul className={classes.ul}>
                                                <li>
                                                    <span>Кредитная история</span>
                                                    <strong>176k</strong>
                                                </li>
                                                <li>
                                                    <span>Информация о доходах</span>
                                                    <strong>97k</strong>
                                                </li>
                                                <li>
                                                    <span>Информация о патентов</span>
                                                    <strong>97k</strong>
                                                </li>
                                            </ul>
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <Box>
                                            <RoundChartIcons />
                                        </Box>
                                        <Box>
                                            <ul className={classes.ul}>
                                                <li>
                                                    <span>Кредитная история</span>
                                                    <strong>376k</strong>
                                                </li>
                                                <li>
                                                    <span>Информация о доходах</span>
                                                    <strong>27k</strong>
                                                </li>
                                                <li>
                                                    <span>Информация о патентов</span>
                                                    <strong>87k</strong>
                                                </li>
                                            </ul>
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        <Box>
                                            <RoundChartIcons />
                                        </Box>
                                        <Box>
                                            <ul className={classes.ul}>
                                                <li>
                                                    <span>Кредитная история</span>
                                                    <strong>276k</strong>
                                                </li>
                                                <li>
                                                    <span>Информация о доходах</span>
                                                    <strong>57k</strong>
                                                </li>
                                                <li>
                                                    <span>Информация о патентов</span>
                                                    <strong>89k</strong>
                                                </li>
                                            </ul>
                                        </Box>
                                    </TabPanel>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center" className={`${classes.mb5} ${classes.mt6}`}>
                        <Grid item xs={6}>
                            <Box className={classes.tableTitle}>
                                <Box className={classes.title}>Сотрудники: 25</Box>
                                <NavLink to="/partnerEmployees" className={classes.link}>Посмотреть всех</NavLink>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align="right">
                                <NavLink to="/newEmployee">
                                    <Button variant="contained" color="primary" size="large" disableElevation>
                                        Новый сотрудник
                                    </Button>
                                </NavLink>
                            </Typography>
                        </Grid>
                    </Grid>
                    {/* <StaffTable /> */}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PartnerStatistic

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
  
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            >
            {value === index && <Box p={3}>{children}</Box>}
            </Typography>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}