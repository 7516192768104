import { makeStyles } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core'

const mainTheme = createMuiTheme({
    typography: {
     "fontFamily": "\"Open Sans\", \"Roboto\", \"Arial\", sans-serif",
     "fontSize": 14,
     "fontWeightLight": 300,
     "fontWeightRegular": 400,
     "fontWeightMedium": 600
    }
})

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 1422
    },
    rootGrid: {
        height: 'calc(100vh - 64px)',
        boxSizing: 'border-box'
    },
    item: {
        padding: '30px 22px',
        position: 'relative'
    }
}))

export { mainTheme, useStyles }