import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import { IOrganizationStatistics } from '../../api/AdminAPI';
import { Button } from '@material-ui/core';
import { sessionStorageSetItem } from '../../utils/storage'

type Props = {
    organizations: IOrganizationStatistics[];
}

const StatisticsTable:React.FC<Props> = ({ organizations }) => {
    let history = useHistory();

    const organizationClick = (organization: IOrganizationStatistics) => {
        sessionStorageSetItem("organizationName", organization.organizationName);
        return history.push(`/statistics/${organization.creditOrganizationId}`);
    };
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell align="left">№</TableCell>
                    <TableCell>Клиент</TableCell>
                    <TableCell align="center">Идентификация</TableCell>
                    <TableCell align="center">Ишеним</TableCell>
                    <TableCell align="center">Доходы</TableCell>
                    <TableCell align="center">Действие</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {organizations.map((organization, index) => (
                    <TableRow key={index}>
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell component="th" scope="row">{organization.organizationName}</TableCell>
                        <TableCell align="center">{organization.countRegistrations}</TableCell>
                        <TableCell align="center">{organization.ishenimRequests}</TableCell>
                        <TableCell align="center">{organization.settlementsService}</TableCell>
                        <TableCell align="center">
                            <Button className="tableLinkBlue" onClick={() => organizationClick(organization)}>Подробнее</Button>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default StatisticsTable
