import React, { Dispatch, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { RootState, actions } from '../../../store'
import { ThunkDispatch } from 'redux-thunk'
import { Action, AnyAction } from 'redux'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Box, Button } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useHistory, useRouteMatch } from 'react-router-dom'
import StaffTable from '../../bricks/StaffTable'
import Search from '../../bricks/Search'
import { AdminAPI, IEmployees } from '../../../api/AdminAPI'
import Pagination from '@material-ui/lab/Pagination'
import {
    sessionStorageGetItem,
    sessionStorageSetItem,
} from '../../../utils/storage'

type Props = ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>

const PartnerEmployees: React.FC<Props> = ({
    sessionId,
    search,
    searchAction,
	canCreateEmployyes
}) => {
    const classes = useStyles()
    let history = useHistory()
    const match = useRouteMatch()
    //@ts-ignore
    const creditOrganizationId = parseInt(match.params.creditOrganizationId)
    const [employees, setEmployees] = useState<IEmployees[]>([])
    const [foundEmployees, setFoundEmployees] = useState(0)
    const [page, setPage] = React.useState(1)
    const limit = 10
    const min = (page - 1) * limit
    const [employeeSuccess, setEmployeeSuccess] = useState(false);

    const organizationName = sessionStorageGetItem('organizationName')

    useEffect(() => {
        if (sessionId) {
            AdminAPI.searchOrganizationEmployees(
                sessionId,
                limit,
                min,
                creditOrganizationId,
                search
            ).then((data) => {
                setEmployees(data.employees)
                setFoundEmployees(data.foundEmployees)
            })
        }
    }, [sessionId, page, min, creditOrganizationId, search, employeeSuccess])

    const paginationChange = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setPage(value)
    }

    let currentCount = (foundEmployees - (foundEmployees % limit)) / limit
    if (foundEmployees % limit > 0) {
        currentCount = currentCount + 1
    }

    const goBackClick = () => {
        searchAction(null)
        return history.goBack()
    }

    const newEmployeeClick = () => {
        sessionStorageSetItem(
            'creditOrganizationId',
            creditOrganizationId.toString()
        )
        return history.push('/newEmployee')
    };

    return (
        <Grid item xs className="contentGrid">
            <Box mb={3}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                        <Button
                            variant="contained"
                            color="inherit"
                            size="large"
                            disableElevation
                            startIcon={<ArrowBackIosIcon />}
                            onClick={goBackClick}
                        >
                            Назад
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h4" className={classes.h4}>
                            {organizationName}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.right}>
                        <Search />
                    </Grid>
                    <Grid item xs={3} className={classes.right}>
                        {canCreateEmployyes  && (
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                disableElevation
                                onClick={newEmployeeClick}
                            >
                                Новый сотрудник
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <StaffTable
                        employees={employees}
                        creditOrganizationId={creditOrganizationId}
                        employeeSuccess={employeeSuccess}
                        setEmployeeSuccess={setEmployeeSuccess}
                    />
                    <Grid item xs={12}>
                        <Box mb={3} mt={3}>
                            <Typography
                                variant="h5"
                                align="right"
                                className={classes.h5}
                            >
                                Сотрудников: {foundEmployees}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className={classes.center}>
                        {foundEmployees > 10 && (
                            <Pagination
                                count={currentCount}
                                page={page}
                                onChange={paginationChange}
                                shape="rounded"
                                className={classes.root}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state: RootState) => ({
    sessionId: state.auth.sessionId,
    search: state.admin.search,
	canCreateEmployyes: state.auth.getUserInfo?.canCreateEmployees,
})

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
) => ({
    searchAction: (search: string | null) =>
        dispatch(actions.admin.searchAction(search)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PartnerEmployees)

const defaultColor = '#83D170'
const useStyles = makeStyles((theme) => ({
    h4: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
        fontSize: 20,
    },
    h5: {
        fontWeight: 600,
        fontSize: 16,
    },
    center: {
        textAlign: 'center',
    },
    right: {
        textAlign: 'right',
    },
    buttonColor: {
        color: '#333333',
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        '& .MuiButtonBase-root': {
            background: '#FFFFFF',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: 4,
            color: '#333333',
            fontSize: 16,
            fontWeight: 700,
            minWidth: 34,
            height: 34,
            '&:hover': {
                backgroundColor: defaultColor,
                color: '#ffffff',
                '& .MuiPaginationItem-icon': {
                    color: '#fff',
                },
            },
            '&.Mui-selected': {
                backgroundColor: defaultColor,
                color: '#ffffff',
            },
            '& .MuiPaginationItem-icon': {
                fontSize: 32,
                color: '#787878',
            },
            '&.Mui-disabled': {
                opacity: 0.7,
            },
        },
    },
}))
