import axios from 'axios';
import { creditBureauUrl } from '../utils/baseUrl';

const instance = axios.create({
    baseURL: `${creditBureauUrl()}api/Auth/`,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "apiKey": "DE57A23D-D449-4279-A2FB-14A3C5F650F2",
    }
})

export const AuthSessionAPI = {
    async auth(sessionId: string) {
        return await instance.get(`Auth/${sessionId}`).then(res => {
            return res.data;
        });
    },
    async logout(sessionId: string) {
        return await instance.get(`Logout/${sessionId}`).then(res => {
            return res.data;
        });
    },
}