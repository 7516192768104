import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link } from '@material-ui/core';
  
  function createData(organization: string, INN: string, registrationDate: string) {
    return { organization, INN, registrationDate };
  }
  
  const partners = [
    createData('Абдышев Азамат Нурланович', '23011198400286', '20.10.2019'),
    createData('Абдышев Азамат Нурланович', '23011198400286', '20.10.2019'),
    createData('Абдышев Азамат Нурланович', '23011198400286', '20.10.2019'),
    createData('Абдышев Азамат Нурланович', '23011198400286', '20.10.2019'),
    createData('Абдышев Азамат Нурланович', '23011198400286', '20.10.2019'),
    createData('Абдышев Азамат Нурланович', '23011198400286', '20.10.2019'),
    createData('Абдышев Азамат Нурланович', '23011198400286', '20.10.2019'),
    createData('Абдышев Азамат Нурланович', '23011198400286', '20.10.2019'),
    createData('Абдышев Азамат Нурланович', '23011198400286', '20.10.2019'),
    createData('Абдышев Азамат Нурланович', '23011198400286', '20.10.2019'),
    createData('Абдышев Азамат Нурланович', '23011198400286', '20.10.2019')
  ];

const SpecialistTable = () => {

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">№</TableCell>
                        <TableCell>Клиент</TableCell>
                        <TableCell align="center">ИНН</TableCell>
                        <TableCell align="center">Дата регистрации</TableCell>
                        <TableCell align="center">Действие</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {partners.map((partner, index) => (
                    <TableRow key={index}>
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell component="th" scope="row">{partner.organization}</TableCell>
                        <TableCell component="th" align="center">ИНН {partner.INN}</TableCell>
                        <TableCell component="th" align="center">{partner.registrationDate}</TableCell>
                        <TableCell align="center">
                            <Link href="#">Посмотреть</Link>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default SpecialistTable