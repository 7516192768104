import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Box, Button, TextField, IconButton, FormControlLabel, Checkbox } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useHistory } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'
import { IGetEmployee } from '../../../store/Admin/types'
import { AdminAPI } from '../../../api/AdminAPI'
import { sessionStorageGetItem } from '../../../utils/storage';
import SnackbarAlert from '../../bricks/SnackbarAlert';

type Props = ReturnType<typeof mapStateToProps>

const AddEmployee:React.FC<Props> = ({ sessionId }) => {
    const classes = useStyles()
    let history = useHistory();
    const creditOrganizationId = sessionStorageGetItem("creditOrganizationId");
    const organizationName = sessionStorageGetItem("organizationName");
    const [notification, setNotification] = useState(false);
    const [errorMessage, setErrorMessage] = useState({
        error: false,
        message: '',
    });

    const catchError = (message: string) => {
        setNotification(true);
        setErrorMessage({error: true, message});
    }

    const [newEmployee, setNewEmployee] = useState<IGetEmployee>({
        creditOrganizationId: creditOrganizationId ? parseInt(creditOrganizationId) : 0,
        employeeId: 0,
        employeeName: '',
        employeeInn: '',
        pin: '',
        phone: '',
        canViewStatistics: true,
        canCreateEmployees: true,
    });

    const handleChange = (prop: keyof IGetEmployee) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewEmployee({ ...newEmployee, [prop]: event.target.value });
    };

    const checkedStateChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewEmployee({ ...newEmployee, [name]: event.target.checked })
    }

    const saveEmploee = () => {
        if(sessionId) {
            AdminAPI.setEmployee(sessionId, newEmployee).then(data => {
                if(data.success === true) {
                    setNotification(true);
                    setErrorMessage({error: false, message: "Вы успешно добавили сотрудника!"});
                }
                return history.goBack();
            }).catch(({response}) => catchError(response.data.message));
        }
    }

    return (
        <Grid item xs className="contentGrid">
            <Box mb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Button 
                            variant="contained" 
                            color="inherit" 
                            size="large" 
                            disableElevation
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.goBack()}>
                            Назад
                        </Button>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography variant="h4" className={classes.h4}>Новый сотрудник</Typography>
                        <Typography variant="h5" className={classes.h5}>{organizationName}</Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.right}>
                        <IconButton onClick={() => history.goBack()}>
                            <CloseIcon className={classes.close} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={2}></Grid>
                <Grid item xs={9}>
                    <form className={classes.root} noValidate autoComplete="off">
                        <Box className={classes.boxShadow} mt={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <TextField 
                                        label="Ф.И.О" 
                                        variant="outlined" 
                                        fullWidth 
                                        value={newEmployee.employeeName} 
                                        onChange={handleChange('employeeName')} 
                                        size="small" />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField 
                                        label="ИНН" 
                                        variant="outlined" 
                                        fullWidth 
                                        value={newEmployee.employeeInn} 
                                        onChange={handleChange('employeeInn')} 
                                        size="small" />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField 
                                        label="Телефон" 
                                        variant="outlined" 
                                        fullWidth 
                                        value={newEmployee.phone} 
                                        onChange={handleChange('phone')} 
                                        size="small" 
                                        placeholder="996111222333" />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField 
                                        label="ПИН-код" 
                                        variant="outlined" 
                                        fullWidth 
                                        value={newEmployee.pin} 
                                        onChange={handleChange('pin')} 
                                        size="small" 
                                        placeholder="****" />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        className={classes.formControlLabel}
                                        control={
                                            <Checkbox
                                                checked={newEmployee.canCreateEmployees}
                                                onChange={checkedStateChange('canCreateEmployees')}
                                                color="primary"
                                            />
                                        }
                                        label="регистрирация пользователей"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        className={classes.formControlLabel}
                                        control={
                                            <Checkbox
                                                checked={newEmployee.canViewStatistics}
                                                onChange={checkedStateChange('canViewStatistics')}
                                                color="primary"
                                            />
                                        }
                                        label="просмотр статистики"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            size="large" 
                            disableElevation 
                            onClick={saveEmploee}>
                            Добавить сотрудника
                        </Button>
                    </form>
                </Grid>
            </Grid>
            <SnackbarAlert 
                notification={notification}
                setNotification={setNotification} 
                message={errorMessage.message}
                severity={errorMessage.error ? "error" : "success"}
                vertical="top" 
                horizontal="center" />
        </Grid>
    )
}

const mapStateToProps = (state: RootState) => ({
    sessionId: state.auth.sessionId,
});

export default connect(mapStateToProps)(AddEmployee);

const useStyles = makeStyles(theme => ({
    root: {
        color: '#000000',
        marginBottom: 25,
        '& .MuiInputBase-root': {
          borderRadius: 8
        }
    },
    confirmation: {
        marginTop: 30,
        '& .MuiInputBase-root': {
            color: '#000000',
            borderRadius: 8
        },
        '& .MuiButtonBase-root': {
            padding: '15px 30px'
        },
        '& .MuiTypography-root': {
            marginBottom: 15
        },
        '& .MuiFormControl-root': {
            maxWidth: 188,
            paddingRight: 20
        }
    },
    h4: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
        fontSize: 32,
        color: '#000000',
        marginBottom: 10
    },
    h5: {
        fontWeight: 600,
        fontSize: 20
    }, 
    center: {
        textAlign: 'center'
    },
    right: {
        textAlign: 'right'
    },
    buttonColor: {
        color: '#333333'
    },
    close: {
        fontSize: 40,
        color: '#FF0000',
    },
    maxWidth: {
        maxWidth: 194
    },
    boxShadow: {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        marginTop: 28,
        padding: 28,
        marginRight: 50,
        marginBottom: 28
    },
    imgDownload: {
        width: 205,
        height: 225,
        backgroundColor: '#E4E4E4',
        borderRadius: 12,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
            opacity: 0.6,
            color: "#000000",
            maxWidth: 170
        }
    },
    imgDownloadSmall: {
        width: 200,
        height: 123,
        backgroundColor: '#E4E4E4',
        borderRadius: 12,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
            opacity: 0.6,
            color: "#000000",
            maxWidth: 170
        }
    },
    formControlLabel: {
        alignItems: 'start',
        '& .MuiButtonBase-root': {
            padding: '0 9px 0'
        },
        '& a': {
            textDecoration: 'underline',
            '&:hover': {
                textDecoration: 'none'
            }
        }
    },
    confirmationBox: {
        display: 'flex'
    }
}))