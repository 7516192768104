import { ActionCreator, Action } from 'redux';
import { AdminActionsTypes } from './types';

export interface IStartDate extends Action<AdminActionsTypes.START_DATE> {
    startDate: Date;
}

export interface IEndDate extends Action<AdminActionsTypes.END_DATE> {
    endDate: Date;
}

export interface IEmployeeId extends Action<AdminActionsTypes.EMPLOYEE_ID> {
    employeeId: number | null;
}

export interface ISearch extends Action<AdminActionsTypes.SEARCH> {
    search: string | null;
}

export type AdminActions =
    | IStartDate
    | IEndDate
    | IEmployeeId
    | ISearch

export const searchAction: ActionCreator<ISearch> = (search: string | null) => {
    return {
        type: AdminActionsTypes.SEARCH,
        search,
    }
}

export const employeeIdAction: ActionCreator<IEmployeeId> = (employeeId: number | null) => {
    return {
        type: AdminActionsTypes.EMPLOYEE_ID,
        employeeId,
    }
}

export const startDateAction: ActionCreator<IStartDate> = (startDate: Date) => {
    return {
        type: AdminActionsTypes.START_DATE,
        startDate,
    }
}

export const endDateAction: ActionCreator<IEndDate> = (endDate: Date) => {
    return {
        type: AdminActionsTypes.END_DATE,
        endDate,
    }
}