import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        color: '#000000',
        '& .MuiInputBase-root': {
          borderRadius: 8
        }
    },
    h4: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
        fontSize: 32,
        marginBottom: 12
    },
    h5: {
        fontWeight: 600,
        fontSize: 20
    },
    h6: {
        fontWeight: 600,
        fontSize: 20,
        display: 'inline-block',
        marginRight: 15
    },
    center: {
        textAlign: 'center'
    },
    right: {
        textAlign: 'right'
    },
    cancel: {
        padding: '11px 30px',
        fontSize: 20,
        lineHeight: '26px',
        textTransform: 'none',
        fontWeight: 600,
        borderRadius: 8,
        boxSizing: 'border-box',
        marginRight: 25
    },
    mt1: {
        marginTop: 5
    },
    mb1: {
        marginBottom: 5
    },
    mb2: {
        marginBottom: 10
    },
    mb3: {
        marginBottom: 15
    },
    mb5: {
        marginBottom: 25
    },
    addGuarantor: {
        color: '#007AFF',
        textDecoration: 'underline',
        cursor: 'pointer',
        transition: 'all .3s ease',
        marginTop: 8,
        '&:hover': {
            textDecoration: 'none'
        }
    }
}))

export { useStyles }