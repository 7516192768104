import { Reducer } from "redux";
import { AdminState, AdminActionsTypes } from "./types";
import { AdminActions } from "./actions";
import moment from "moment";
const startOfMonth = moment().startOf('month').toDate();
const defaultState: AdminState = {
    startDate: startOfMonth,
    endDate: new Date(),
    employeeId: null,
    search: null,
};

export const authReducer: Reducer<AdminState, AdminActions> = (state = defaultState, action) => {

    switch (action.type) {
        case AdminActionsTypes.SEARCH:
            return {
                ...state,
                search: action.search,
            };
        case AdminActionsTypes.EMPLOYEE_ID:
            return {
                ...state,
                employeeId: action.employeeId,
            };
        case AdminActionsTypes.START_DATE:
            return {
                ...state,
                startDate: action.startDate,
            };
        case AdminActionsTypes.END_DATE:
            return {
                ...state,
                endDate: action.endDate,
            };
        default:
            return state;
    }
};

export default authReducer;
