import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'
  
  function createData(id: number, client: string, INN: string, date: string, role: string, document: string) {
    return {id, client, INN, date, role, document  }
  }
  
  const documents = [
    createData(1, 'Абдышев Азамат Нурланович', 'ИНН 23011198400286', '20.10.2019', 'Заемщик', 'Договор займа'),
    createData(2, 'Абдышев Азамат Нурланович', 'ИНН 23011198400286', '20.10.2019', 'Поручитель', 'Справка о доходах'),
    createData(3, 'Абдышев Азамат Нурланович', 'ИНН 23011198400286', 'Рассматривается', 'Супруг', 'Согласие супруга')
  ];

const AgreementTable = () => {
    const classes = useStyles()

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell align="left">№</TableCell>
                    <TableCell>Клиент</TableCell>
                    <TableCell align="left">Дата согласия</TableCell>
                    <TableCell align="left">Роль</TableCell>
                    <TableCell align="left">Документ</TableCell>
                    <TableCell align="center">Документ</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {documents.map((document, index) => (
                    <TableRow key={index}>
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell component="th" scope="row">
                            <div>{document.client}</div>
                            <span>{document.INN}</span>
                        </TableCell>
                        <TableCell align="left" className={document.id === 3 ? classes.yellow : ''}>{document.date}</TableCell>
                        <TableCell align="left">{document.role}</TableCell>
                        <TableCell align="left">{document.document}</TableCell>
                        <TableCell align="center">
                            <NavLink className={classes.link} to="#">Посмотреть</NavLink>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default AgreementTable

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'none'
        }
    },
    yellow: {
        fontWeight: 600,
        color: '#FF9900'
    }
  })
)