import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        color: '#000000',
        '& .MuiInputBase-root': {
          borderRadius: 8
        }
    },
    h4: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
        fontSize: 32
    },
    h5: {
        fontWeight: 600
    }, 
    center: {
        textAlign: 'center'
    },
    right: {
        textAlign: 'right'
    },
    cancel: {
        marginRight: 25
    },
    mt1: {
        marginTop: 5
    },
    mb1: {
        marginBottom: 5
    },
    mb3: {
        marginBottom: 15
    },
    mb5: {
        marginBottom: 25
    }
}))

export { useStyles }