import axios from 'axios';
import { baseUrl } from '../utils/baseUrl';

const instance = axios.create({
    baseURL: `${baseUrl()}api/Auth/`,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "apiKey": "3D897346-3583-4ED5-81D2-E3D8D06DF6E3",
    }
})

export const AuthAPI = {
    async getNameByInn(inn: string) {
        return await instance.post('GetNameByInn', {inn})
    },

    async employeeCheck(innOrganisation: string, innEmployee: string) {
        return await instance.post('EmployeeCheck', {innOrganisation, innEmployee}).then(res => {
            return res.data;
        })
    },

    async employeePinConfirm(pin: string, innOrganisation: string, innEmployee: string) {
        return await instance.post('EmployeePinConfirm', {pin, innOrganisation, innEmployee}).then(res => {
            return res.data;
        })
    }
}