import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Box, Typography } from '@material-ui/core'
import MenuDrawer from '../../MenuDrawer/MenuDrawer'

const Сustomers = () => {
    const classes = useStyles()

    return (
        <>
            <MenuDrawer />
            <Grid item xs className="contentGrid">
                <Box mb={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4" className={classes.h4}>Клиенты</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </>
    )
};

export default Сustomers

const useStyles = makeStyles(theme => ({
    h4: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
        fontSize: 32
    }
}));
