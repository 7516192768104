import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        color: '#000000',
        '& .MuiInputBase-root': {
          borderRadius: 8
        }
    },
    h4: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
        fontSize: 32,
        marginBottom: 12
    },
    h5: {
        fontWeight: 600,
        fontSize: 20
    },
    h6: {
        fontWeight: 600,
        fontSize: 20,
        display: 'inline-block',
        marginRight: 15
    },
    center: {
        textAlign: 'center'
    },
    right: {
        textAlign: 'right'
    },
    cancel: {
        padding: '11px 30px',
        fontSize: 20,
        lineHeight: '26px',
        textTransform: 'none',
        fontWeight: 600,
        borderRadius: 8,
        boxSizing: 'border-box',
        marginRight: 25
    },
    mt1: {
        marginTop: 5
    },
    mb1: {
        marginBottom: 5
    },
    mb2: {
        marginBottom: 10
    },
    mb3: {
        marginBottom: 15
    },
    mb5: {
        marginBottom: 25
    },
    boxShadow: {
        boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.15)',
        borderRadius: 12,
        padding: 24,
        color: '#333'
    },
    boxWrap: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    container: {
        display: 'flex',
        marginRight: -12,
        marginLeft: -12
    },
    containerItem: {
        margin: 12,
        maxWidth: 360,
        boxSizing: 'border-box',
        width: '100%'
    },
    containerItemRight: {
        maxWidth: '100%',
        width: '100%'
    },
    boxWrapItem: {
        marginTop: 12,
        marginBottom: 12,
        marginRight: 42,
        '&:nth-child(3n+3)': {
            marginRight: 0
        },
        '& span': {
            color: '#000000',
            fontSize: 12,
            display: 'block',
            marginBottom: 8
        },
        '& strong': {
            fontWeight: 600,
            fontSize: 16
        }
    }
}))

export { useStyles }