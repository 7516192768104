export interface AuthState {
    sessionId: string | null;
    getUserInfo: GetUserInfo | null;
    isAuthorized: boolean;
    loading: boolean;
}

export enum AuthActionsTypes {
    EMPLOYEE_PIN_CONFIRM = 'EMPLOYEE_PIN_CONFIRM',
    GET_USER_INFO = 'GET_USER_INFO',
    SET_LOADER = 'SET_LOADER',
    LOGOUT = 'LOGOUT',
}

export interface GetUserInfo {
    sessionId: string,
    organizationInn: string,
    organizationName: string,
    employeeInn: string,
    employeeName: string,
    canViewStatistics: boolean,
    canCreateEmployees: boolean,
    isIshenim: boolean,
    organizationId: number,
    employeeId: number,
}

export enum Roles {
    User = 'User',
    Moderator = 'Moderator',
    CompanyAdmin = 'CompanyAdmin',
    SuperAdmin = 'SuperAdmin',
}
  